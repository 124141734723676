import React from "react";
import { useTranslation } from "react-i18next";
import { Margin } from "../custom-components/checkbox";


function Terms() {

    const { t } = useTranslation();


    return (
        <div className="container">
            <Margin />
            <div className="row">
                <a href="/" className="row small px-3 whiteFront">
                    <button className="orangeBack">
                        {t("back")}
                    </button>
                </a>
            </div>
            <Margin />
            <h2 className="orangeFront">{t("terms_of_use")}</h2>

            <div>
                <p className="orangeFront">Artikel 1: Toepasselijkheid</p>

                <p>
                    1. Door het gebruik van de App (waaronder mede wordt verstaan
                    het downloaden en de installatie daarvan), aanvaardt de
                    Gebruiker deze gebruiksvoorwaarden, de privacyverklaring
                    en het cookiebeleid van de applicatie. Onder Gebruiker wordt in
                    deze gebruiksvoorwaarden verstaan de natuurlijke en/of
                    rechtspersoon, die van de App gebruikmaakt.
                </p>
                <p>
                    2. Deze gebruiksvoorwaarden, de privacyverklaring en het
                    cookiebeleid van de applicatie zijn (tevens) te raadplegen,
                    downloaden en printen op www.scriptlab.nl.
                </p>
                <p>
                    3. Indien de App gebruikmaakt van diensten van derden,
                    zijn tevens de algemene voorwaarden en het privacy- en
                    cookiebeleid van de betreffende derden van toepassing.
                    De applicatie is niet verantwoordelijk voor de diensten en het
                    privacy- en cookiebeleid van derden.
                </p>
            </div>
        </div>
    );
}

export default Terms;
