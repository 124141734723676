import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

import { data_access } from "../data-access";
import "./login.css";

function Login() {
  const [login, setUser] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);

  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const rememberedUser = async () => {
    let rememberMe = Cookies.get("rememberMe");
    let userData = Cookies.get("userData");

    if (!!userData && !!rememberMe && rememberMe === "true") {
      userData = JSON.parse(userData);
      var f = new FormData();
      f.append("token", userData.token);
      f.append("serie", userData.serie);
      f.append("email", userData.email);
      f.append("METHOD", "doTokenMatchLogin");
      const id = toast.loading(t("loading_token_match_login"));
      await sleep(1000);
      try {
        await axios.post(data_access.API_URL, f).then((response) => {
          Cookies.set("isLogged", "true", {
            expires: 1,
            secure: true,
            sameSite: "strict",
            path: "/",
          });
          Cookies.set("rememberMe", rememberMe, {
            expires: 1,
            secure: true,
            sameSite: "strict",
            path: "/",
          });
          Cookies.set("userData", JSON.stringify(response.data), {
            expires: 1,
            secure: true,
            sameSite: "strict",
            path: "/",
          });

          localStorage.setItem("success", JSON.stringify([t("login_success")]));

          window.location = `/${i18n.language}/dashboard`;
        });
      } catch (e) {
        console.log(e);
        toast.update(id, {
          render: t("auto_login_failed_credentials_incorrect"),
          type: "error",
          isLoading: false,
        });
      }
    }
  };
  React.useEffect(() => {
    rememberedUser();
  });

  const doLogin = async () => {
    var f = new FormData();
    f.append("email", login.email);
    f.append("password", login.password);
    f.append("METHOD", "doLogin");
    f.append("rememberMe", rememberMe);
    const id = toast.loading(t("loading"), { autoclose: 3000 });
    try {
      await sleep(1000);
      await axios.post(data_access.API_URL, f).then((response) => {
        // console.log(response);

        // localStorage.setItem("userData", JSON.stringify(response.data));
        // localStorage.setItem("isLogged", true);
        // localStorage.setItem("rememberMe", rememberMe); //catching remember me check

        Cookies.set("isLogged", "true", {
          expires: 1,
          secure: true,
          sameSite: "strict",
          path: "/",
        });
        Cookies.set("rememberMe", rememberMe, {
          expires: 1,
          secure: true,
          sameSite: "strict",
          path: "/",
        });
        Cookies.set("userData", JSON.stringify(response.data), {
          expires: 1,
          secure: true,
          sameSite: "strict",
          path: "/",
        });
        localStorage.setItem("success", JSON.stringify([t("login_success")]));
        return (window.location = `/${i18n.language}/dashboard`);
      });
    } catch (e) {
      // create little toast for invalid credentials
      const email = e.response.data.email;
      toast.update(id, {
        render: t(e.response.data.msg, { email }),
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // console.error(e);
    }
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <div className="d-flex flex-column min-vh-100">
      <section className="">
        {/* <!-- Jumbotron --> */}
        <div
          className="px-4 py-5 px-md-5 text-center text-lg-start"
          
        >
          <div className="container">
            <div className="row gx-lg-5 align-items-center">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <h1 className="my-5 display-3 fw-bold ls-tight orangeFront">
                  {t("reports")} <br />
                  <span className="" style={{ color: "hsl(217, 10%, 50.8%)" }}>
                    {t("with_high_standards")}
                  </span>
                </h1>
                <p style={{ color: "hsl(217, 10%, 50.8%)" }}>
                  {t(
                    "reporting_system_for_maintenance_on_heating_installations"
                  )}
                </p>
              </div>

              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="card">
                  <div className="card-body py-5 px-md-5">
                    {/* <!-- 2 column grid layout with text inputs for the first and last names --> */}
                    {/* <!-- Email input --> */}
                    <div className="form-outline mb-4">
                      <input
                        type="email"
                        id="form3Example3"
                        className="form-control lightgreyBack"
                        name="email"
                        onChange={handleChange}
                      />
                      <label className="form-label" htmlFor="form3Example3">
                        {t("email_address")}
                      </label>
                    </div>

                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                      <input
                        type="password"
                        id="form3Example4"
                        className="form-control lightgreyBack"
                        name="password"
                        onChange={handleChange}
                      />
                      <label className="form-label" htmlFor="form3Example4">
                        {t("password")}
                      </label>
                    </div>

                    <div className="row mb-4">
                      <div className="col d-flex justify-content-center">
                        {/* <!-- Checkbox --> */}
                        <div className="form-check">
                          <input
                            className="form-check-input lightgreyBack"
                            type="checkbox"
                            id="form2Example31"
                            onClick={() => {
                              setRememberMe(!rememberMe);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="form2Example31"
                          >
                            {t("remember_me")}
                          </label>
                        </div>
                      </div>

                      <div className="col">
                        {/* <!-- Simple link --> */}
                        <a href="#!">{t("forgot_password_question")}</a>
                      </div>
                    </div>

                    {/* <!-- Submit button --> */}
                    <button
                      className="btn orangeBack btn-block mb-4"
                      onClick={() => doLogin()}
                    >
                      {t("sign_in")}
                    </button> 
                    <div className="lightgreyFront text-center">
                      {t("agree_terms_of_use")}
                    </div>
                    

                    {/* <!-- Register buttons --> */}
                    <div className="text-center ">
                      <a href={"/"+i18n.language+"/terms"} className="small whiteFront">
                        {t("terms_of_use")}
                      </a>

                      <a href={"/"+i18n.language+"/privacy"} className="small px-4 whiteFront">
                        {t("privacy_policy")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
