import Cookies from "js-cookie";
import React from "react";
import UserCRUD from "./UserCRUD/UserCRUD";

function Settings() {
  const viewPermissions = JSON.parse(Cookies.get("userData"))["user"][3];
  return (
    <div>
      {viewPermissions === "1" && <UserCRUD />}
      <h1>Settings </h1>
    </div>
  );
}

export default Settings;
