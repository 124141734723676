import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";


import Dashboard from "./dashboard/Dashboard";
import ReportCRUD from "./manage_report/ReportCRUD";
import Client from "./client/Client";
import Settings from "./settings/settings";
import Login from "./login/login";
import Terms from "./terms_of_use/terms";
import Privacy from "./privacy_policy/privacy";

// import Menu from "./menu/Menu";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";
import ProtectedRoute from "./protected-routes";
import Theme, { ThemeContext } from "./Theme";


function App() {

  const NavLayout = () => (
    <>
      <Navbar />
      <Outlet />
    </>
  );


  const About = () => {
    return <h1>about</h1>;
  };

  const { i18n } = useTranslation();
  const isLogged = () => {
    if (Cookies.get("isLogged") === "true") {
      return true;
    } else {
      return false;
    }
  };
  const doPrintMSG = () => {
    if (!!localStorage.success) {
      let success = JSON.parse(localStorage.getItem("success"));

      success.forEach((msg) => {
        toast.success(msg);
      });

      delete localStorage.success;
    }
    if (!!localStorage.error) {
      let error = JSON.parse(localStorage.getItem("error"));

      error.forEach((msg) => {
        toast.error(msg);
      });

      delete localStorage.error;
    }
  };
  React.useEffect(() => {
    doPrintMSG();
  }, []);
  return (
    <div>
      <Theme>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path={`/`} element={<Login />} />
          <Route path={`/${i18n.language}`} element={<Login />} />
          <Route path={`/${i18n.language}/*`}>
            <Route path={`terms`} element={<Terms />} />
            <Route path={`privacy`} element={<Privacy />} />
          </Route>

          <Route
            element={
              <ProtectedRoute
                isAllowed={isLogged()}
                redirectPath={`/${i18n.language}`}
              />
            }
          >
            <Route path={`/${i18n.language}/*`} element={<NavLayout />}>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="report" element={<ReportCRUD />} />
              <Route path="client" element={<Client />}></Route>
              <Route path="settings" element={<Settings />}></Route>
              <Route path="about" element={<About />}></Route>
            </Route>
          </Route>
        </Routes>
        <Footer />
      </Theme>
    </div>
  );
}

export default App;
