import React from "react";
import "./checkbox.scss";

const Checkbox = ({ id, name, label, value, onChange }) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const SwitchButton = ({ id, name, label, value, onChange }) => {
  return (
    <label className="toggle" htmlFor={id}>
      <input
        type="checkbox"
        className="toggle__input"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        checked={value}
      />
      <span className="toggle-track">
        <span className="toggle-indicator">
          {/* <!-- 	This check mark is optional	 --> */}
          <span className="checkMark">
            <svg
              viewBox="0 0 24 24"
              id="ghq-svg-check"
              role="presentation"
              aria-hidden="true"
            >
              <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
            </svg>
          </span>
        </span>
      </span>
      {label}
    </label>
  );
};

const Margin = ({ top, children }) => {
  return <div style={{ marginTop: top }}>{children}</div>;
};

Margin.defaultProps = {
  top: 20 + "px",
};

const DataTable = ({
  data,
  selectPage,
  options,
  search_input,
  entries_reference,
  handle_entry_change,
  children,
}) => {
  return (
    <>
      {(options || search_input) && (
        <div>
          {options && (
            <div>
              <select
                name="entries"
                id="entries"
                onChange={handle_entry_change}
              >
                {options.map((entry) => {
                  return (
                    <option value={entry.value} key={entry.value}>
                      {entry.text}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {search_input && (
            <div>
              <span>{search_input.text}</span>
              <input
                type="text"
                name="filter"
                id="filter"
                className={search_input.style}
              />
            </div>
          )}
        </div>
      )}
      {children}
      <div>
        {entries_reference && <div>{entries_reference}</div>}

        <div>
          <button className="btn" onClick={() => selectPage(data[0])}>
            First
          </button>
          <button className="btn">Previous</button>
          {data.map((page, index) => {
            return (
              <button
                key={index}
                onClick={() => selectPage(page)}
                className="btn btn-secondary"
                style={{ margin: 1 + "px" }}
              >
                {index + 1}
              </button>
            );
          })}
          <button className="btn">Next</button>
          <button
            className="btn"
            onClick={() => selectPage(data[data.length - 1])}
          >
            Last
          </button>
        </div>
      </div>
    </>
  );
};

export { SwitchButton, Margin, DataTable };
export default Checkbox;
