import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";

import "./navbar.css";
import { data_access } from "../data-access";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  function animation() {
    var tabsNewAnim = $("#navbarSupportedContent");
    var activeItemNewAnim = tabsNewAnim.find(".active");
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: activeWidthNewAnimWidth + "px",
    });
    $("#navbarSupportedContent").on("click", "li", function (e) {
      $("#navbarSupportedContent ul li").removeClass("active");
      $(this).addClass("active");
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        top: itemPosNewAnimTop.top + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: activeWidthNewAnimWidth + "px",
      });
    });
  }

  useEffect(() => {
    // Get current path and find target link
    $("#navbarSupportedContent ul li").removeClass("active");
    var path = window.location.pathname.split("/");
    path = "/" + path[path.length - 2] + "/" + path[path.length - 1];

    // Account for home page with empty path
    if (path === "") {
      path = "index.html";
    }
    var target = $('#navbarSupportedContent ul li a[href="' + path + '"]');
    // Add active class to target link
    target.parent().addClass("active");
    animation();
    function handleResize() {
      setTimeout(animation, 500);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logout = async () => {
    var userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("email", userData.email);
    f.append("token", userData.token);
    f.append("serie", userData.serie);
    f.append("METHOD", "doLogout");
    Cookies.remove("isLogged");
    Cookies.remove("rememberMe");
    Cookies.remove("userData");
    try {
      await axios.post(data_access.API_URL, f).then((response) => {
        if (
          !!response.data.operation &&
          response.data.operation !== "success"
        ) {
          localStorage.setItem("error", JSON.stringify([t("logout_error")]));
        }
      });
    } catch (e) {
      localStorage.setItem("error", JSON.stringify([t("logout_error")]));
    }
  };

  return (
    <nav className="my-nav-bar navbar-expand-lg navbar-mainbg">
      <NavLink className="navbar-my-brand navbar-logo" to="/">
        MIB Installatietechniek b.v.
      </NavLink>

      <button
        className="navbar-toggler navbar-wrapper"
        onClick={function () {
          console.log("click");
          setTimeout(function () {
            animation();
          });
        }}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        &#9776;
      </button>

      <div
        className="collapse navbar-collapse reverse-flex"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li className="nav-item active">
            <NavLink
              className="nav-link"
              to={{ pathname: `/${i18n.language}/dashboard` }}
            >
              {t("dashboard")}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={{ pathname: `/${i18n.language}/report` }}
            >
              {t("reports")}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={{ pathname: `/${i18n.language}/client` }}
            >
              {t("clients")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={{ pathname: `/${i18n.language}/settings` }}
            >
              {t("settings")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={{ pathname: `/${i18n.language}/about` }}
            >
              {t("contacts")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link logout-button"
              to="/"
              onClick={() => logout()}
            >
              <div className="left"></div>
              {t("log_out")}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;
