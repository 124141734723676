import React from "react";
import { useTranslation } from "react-i18next";


function Privacy() {

    const { t } = useTranslation();


    return (
        <div className="container">
            <a href="/" className="row small whiteFront">
                {t("back")}
            </a>

            <h2 className="orangeFront">{t("privacy_policy")}</h2>

            <div>
                <p className="orangeFront">Artikel 1: Toepasselijkheid</p>
                1. Door het gebruik van de App (waaronder mede wordt verstaan
                het downloaden en de installatie daarvan), aanvaardt de
                Gebruiker deze gebruiksvoorwaarden, de privacyverklaring
                en het cookiebeleid van de applicatie. Onder Gebruiker wordt in
                deze gebruiksvoorwaarden verstaan de natuurlijke en/of
                rechtspersoon, die van de App gebruikmaakt.
                2. Deze gebruiksvoorwaarden, de privacyverklaring en het
                cookiebeleid van de applicatie zijn (tevens) te raadplegen,
                downloaden en printen op www.scriptlab.nl.
                3. Indien de App gebruikmaakt van diensten van derden,
                zijn tevens de algemene voorwaarden en het privacy- en
                cookiebeleid van de betreffende derden van toepassing.
                De applicatie is niet verantwoordelijk voor de diensten en het
                privacy- en cookiebeleid van derden.
            </div>
        </div>
    );
}

export default Privacy;
