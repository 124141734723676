import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import { data_access } from "../data-access";
import "./dashboard.css";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const p = payload[0];
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload["date"]}`} </p>
        <p className="label">{`${payload[0].payload["count"]}`}</p>
      </div>
    );
  }

  return null;
};

function Dashboard() {
  const [clientSummary, setclientSummary] = useState([]);
  const [totalClients, setTotalClients] = useState([]);
  const [userSummary, setUserSummary] = useState([]);
  const [usersOnline, setUsersOnline] = useState([]);

  const { t } = useTranslation();

  const doGetDashboardSummary = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("METHOD", "doGetDashboardData");
    f.append("authemail", userData.email);
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    try {
      await axios.post(`${data_access.API_URL}`, f).then((response) => {
        setclientSummary(response.data.client.summary.map(d => ({...d , "count" : +d.count })));
        setTotalClients(response.data.client.total);
        setUserSummary(response.data.user.summary.map(d => ({...d , "count" : +d.count })));
        setUsersOnline(response.data.user.total);
        // console.log(response.data.client.summary.map(d => ({...d , "count" : +d.count })));
      });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(t("insufficient_permissions"));
      }
    }
  };
  useEffect(() => {
    if (!!Cookies.get("userData")) {
      doGetDashboardSummary();
    }
  }, []);
  return (
    <div>
      <div className="row m-t-25" style={{ marginRight: 0, marginLeft: 0 }}>
        <div className="col-sm-6 col-lg-3">
          <div className="overview-item overview-item--c1">
            <div className="overview__inner">
              <div className="overview-box clearfix">
                <div className="icon">
                  <i className="zmdi zmdi-account-o"></i>
                </div>
                <div className="text">
                  <h2>{totalClients}</h2>
                  <span>{t("clients")}</span>
                </div>
              </div>
              <div className="overview-chart">
                <ResponsiveContainer width="100%" height="106%">
                  <AreaChart
                    width={200}
                    height={60}
                    data={clientSummary}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                    <Tooltip content={<CustomTooltip />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="overview-item overview-item--c2">
            <div className="overview__inner">
              <div className="overview-box clearfix">
                <div className="icon">
                  <i className="zmdi zmdi-shopping-cart"></i>
                </div>
                <div className="text">
                  <h2>482</h2>
                  <span>{t("reports")}</span>
                </div>
              </div>
              <div className="overview-chart">
                <ResponsiveContainer width="100%" height="106%">
                  <AreaChart
                    width={200}
                    height={60}
                    data={clientSummary}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="rgb(67, 203, 192)"
                      fill="rgb(67, 203, 192)"
                    />
                    <Tooltip content={<CustomTooltip />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="overview-item overview-item--c3">
            <div className="overview__inner">
              <div className="overview-box clearfix">
                <div className="icon">
                  <i className="zmdi zmdi-calendar-note"></i>
                </div>
                <div className="text">
                  <h2>25</h2>
                  <span>{t("reports_this_week")}</span>
                </div>
              </div>
              <div className="overview-chart">
                <ResponsiveContainer width="100%" height="106%">
                  <AreaChart
                    width={200}
                    height={60}
                    data={clientSummary}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="rgb(255, 59, 171)"
                      fill="rgb(255, 59, 171)"
                    />
                    <Tooltip content={<CustomTooltip />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="overview-item overview-item--c4">
            <div className="overview__inner">
              <div className="overview-box clearfix">
                <div className="icon">
                  <i className="zmdi zmdi-money"></i>
                </div>
                <div className="text">
                  <h2>{usersOnline}</h2>
                  <span>{t("users_online")}</span>
                </div>
              </div>
              <div className="overview-chart">
                <ResponsiveContainer width="100%" height="106%">
                  <AreaChart
                    width={200}
                    height={60}
                    data={userSummary}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="rgb(119, 232, 123)"
                      fill="rgb(119, 232, 123)"
                    />
                    <Tooltip content={<CustomTooltip />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
