import React, { Component } from "react";
import jsQR from "jsqr";
const { requestAnimationFrame } = global;

class QRScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notEnabled: true,
      loading: true,
      video: null,
      stream: null,
    };
  }

  componentDidMount() {
    const video = document.createElement("video");
    const canvasElement = document.getElementById("qrCanvas");
    const canvas = canvasElement.getContext("2d");
    this.setState({
      video,
    });
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment",
        },
      })
      .then(function (stream) {
        video.srcObject = stream;
        video.setAttribute("playsinline", true);
        video.play();
        requestAnimationFrame(tick);
      });

    let stoprecurssion = false;

    const tick = () => {
      if (stoprecurssion) {
        return;
      }

      if (this.state.notEnabled)
        this.setState({
          notEnabled: false,
        });

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        if (this.state.loading)
          this.setState({
            loading: false,
          });
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        var imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });

        if (code && code.data !== "") {
          stoprecurssion = true;
          this.props.onFind(code.data);
        }
      }

      requestAnimationFrame(tick);
    };
  }

  componentWillUnmount() {
    this.state.video.pause();
    this.state.video.srcObject.getTracks().forEach(function (track) {
      track.stop();
    });
  }

  render() {
    let message;

    if (this.state.notEnabled) {
      message = React.createElement(
        "div",
        null,
        React.createElement(
          "span",
          {
            role: "img",
            "aria-label": "camera",
          },
          "\uD83C\uDFA5"
        ),
        " Unable to access video stream (please make sure you have a webcam enabled)"
      );
    } else if (this.state.loading) {
      message = React.createElement(
        "div",
        null,
        React.createElement(
          "span",
          {
            role: "img",
            "aria-label": "time",
          },
          "\u231B"
        ),
        " Loading video..."
      );
    }

    return React.createElement(
      "div",
      null,
      message,
      React.createElement("canvas", {
        id: "qrCanvas",
      })
    );
  }
}

export default QRScan;
