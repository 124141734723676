import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Margin } from "../../custom-components/checkbox";
import ReactPaginate from "react-paginate";
import { Icon, InlineIcon } from "@iconify/react";
import ReactTooltip from "react-tooltip";

import { SwitchButton } from "../../custom-components/checkbox";
import { data_access } from "../../data-access";
import "./UserCRUD.css";

function UserCRUD() {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const userPermissions = JSON.parse(Cookies.get("userData"))["user"];

  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [force_page, set_force_page] = useState(-1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemOffset, setItemOffset] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);
  const [permissions, set_permissions] = useState({
    user_view_permission: false,
    user_create_permission: false,
    user_edit_permission: false,
    user_delete_permission: false,
    client_view_permission: false,
    client_create_permission: false,
    client_edit_permission: false,
    client_delete_permission: false,
    report_view_permission: false,
    report_create_permission: false,
    report_edit_permission: false,
    report_delete_permission: false,
  });
  const [selecteduser, setSelectedUser] = useState({
    email: { value: "", valid: false },
    first_name: { value: "", valid: false },
    last_name: { value: "", valid: false },
    maintenance_number: { value: "", valid: false },
    status: { value: "active", valid: true },
    auth_rol: { value: "user", valid: true },
  });

  const status_options = [
    { value: "active", label: t("active") },
    { value: "inactive", label: t("inactive") },
    { value: "banned", label: t("banned") },
  ];
  const authrol_options = [
    { value: "guest", label: t("guest") },
    { value: "user", label: t("user") },
    { value: "admin", label: t("admin") },
  ];
  const pagination_options = [
    { value: "2", label: "2" },
    { value: "4", label: "4" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
  ];
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "1px solid #ffffff",
      background: "#df8702",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "none",
      background: "#df8702",
    }),
    singleValue: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
    }),
    menuList: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      background: "#3d3d3d",
    }),
  };

  const doGetUsersList = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("email", userData.email);
    f.append("token", userData.token);
    f.append("serie", userData.serie);
    f.append("METHOD", "GET");
    try {
      await axios.post(`${data_access.API_URL}user.php`, f).then((response) => {
        setData(response.data);
      });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(t("insufficient_permissions"));
      } else {
        console.error(e);
      }
    }
  };
  useEffect(() => {
    if (!!Cookies.get("userData")) {
      doGetUsersList();
    }
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      // Fetch items from another resources.
      const endOffset = itemOffset + parseInt(itemsPerPage);
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / parseInt(itemsPerPage)));
      set_force_page(endOffset / parseInt(itemsPerPage) - 1);
    }
  }, [itemOffset, itemsPerPage, data]);

  const change_pagination = (v, e) => {
    setItemsPerPage(v.value);
    setItemOffset(0);
    set_force_page(0);
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setSelectedUser((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   // console.log(selecteduser);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // console.log(e);
    if (e.target.attributes["pattern"]) {
      const pattern = e.target.attributes["pattern"].value;
      let re = new RegExp(pattern);
      var valid = re.test(value);
      if (valid) {
        e.target.classList.remove("validate-form-element");
      } else {
        e.target.classList.add("validate-form-element");
      }
      // console.log(pattern, valid, value);
    }
    setSelectedUser((prevState) => ({
      ...prevState,
      [name]: { value: value, valid: valid },
    }));
  };
  const handle_Select_options = (newValue, element) => {
    const name = element.name;
    const value = newValue.value;
    setSelectedUser((prevState) => ({
      ...prevState,
      [name]: { value: value, valid: true },
    }));
  };

  const handle_permission_checkbox_cLick = (e) => {
    const { name, checked } = e.target;

    set_permissions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // console.log(permissions);
  };

  const selectUser = (user, action) => {
    let temp = JSON.parse(JSON.stringify(user));
    temp["new_email"] = temp["email"];
    let user_permission = temp["user"];
    let client_permission = temp["client"];
    let report_permission = temp["report"];
    let slected_permissions = {
      user_view_permission: user_permission[3] === "1",
      user_create_permission: user_permission[2] === "1",
      user_edit_permission: user_permission[1] === "1",
      user_delete_permission: user_permission[0] === "1",
      client_view_permission: client_permission[3] === "1",
      client_create_permission: client_permission[2] === "1",
      client_edit_permission: client_permission[1] === "1",
      client_delete_permission: client_permission[0] === "1",
      report_view_permission: report_permission[3] === "1",
      report_create_permission: report_permission[2] === "1",
      report_edit_permission: report_permission[1] === "1",
      report_delete_permission: report_permission[0] === "1",
    };
    delete temp["user"];
    delete temp["client"];
    delete temp["report"];
    set_permissions(slected_permissions);
    let tmp = {};
    Object.keys(temp).map((index) => {
      tmp[index] = { value: temp[index], valid: true };
      return true;
    });
    // console.log(tmp);
    setSelectedUser(tmp);
    action === "edit" ? opencloseModalEdit() : opencloseModalDelete();
  };
  const opencloseModalInsert = () => {
    setModalAdd(!modalAdd);
  };
  const opencloseModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  const opencloseModalDelete = () => {
    setModalDelete(!modalDelete);
  };

  const addFunction = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("METHOD", "POST");
    f.append("authemail", userData.email);
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);

    f.append("email", selecteduser.email.value);
    f.append("password", selecteduser.password.value);
    f.append("first_name", selecteduser.first_name.value);
    f.append("last_name", selecteduser.last_name.value);
    f.append("maintenance_number", selecteduser.maintenance_number.value);
    f.append("status", selecteduser.status.value);
    f.append("auth_rol", selecteduser.auth_rol.value);
    f.append("permissions", JSON.stringify(permissions));
    console.log(selecteduser, permissions);
    await axios
      .post(`${data_access.API_URL}user.php`, f)
      .then((response) => {
        setData(data.concat(response.data));
        opencloseModalInsert();
        toast.success(t("user_add_success"));

        // console.log(response);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          opencloseModalInsert();
          toast.error(t("insufficient_permissions"));
        } else {
          opencloseModalInsert();
          console.error(e);
        }
      });
  };

  const editFunction = async () => {
    if (selecteduser.id.value !== "999") {
      const userData = JSON.parse(Cookies.get("userData"));
      var f = new FormData();
      // console.log(selecteduser);
      f.append("authemail", userData.email);
      f.append("authtoken", userData.token);
      f.append("authserie", userData.serie);
      f.append("METHOD", "PUT");
      f.append("email", selecteduser.email.value);
      f.append("new_email", selecteduser.new_email.value);
      f.append("id", selecteduser.id.value);
      if (typeof selecteduser.password !== "undefined") f.append("password", selecteduser.password.value);
      f.append("first_name", selecteduser.first_name.value);
      f.append("last_name", selecteduser.last_name.value);
      f.append("maintenance_number", selecteduser.maintenance_number.value);
      f.append("status", selecteduser.status.value);
      f.append("auth_rol", selecteduser.auth_rol.value);
      f.append("permissions", JSON.stringify(permissions));
      await axios.post(`${data_access.API_URL}user.php`, f).then((response) => {
        let newData = data;
        newData.forEach((user) => {
          if (user.id === selecteduser.id.value) {
            user.email = selecteduser.new_email.value;
            user.first_name = selecteduser.first_name.value;
            user.last_name = selecteduser.last_name.value;
            user.status = selecteduser.status.value;
            user.maintenance_number = selecteduser.maintenance_number.value;
            user.auth_rol = selecteduser.auth_rol.value;
            user.user = response.data.user;
            user.client = response.data.client;
            user.report = response.data.report;
          }
        });
        setData(newData);
        opencloseModalEdit();
        toast.success(t("user_edit_success"));
        // console.log(response);
      });
    } else {
      toast.error("This user cannot be changed !");
    }
  };

  const delFunction = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("email", selecteduser.email);
    f.append("METHOD", "DELETE");
    f.append("authemail", userData.email);
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    console.log(selecteduser);
    await axios.post(`${data_access.API_URL}user.php`, f).then((email) => {
      opencloseModalDelete();
      setData(data.filter((user) => user.email !== selecteduser.email));
      toast.success(t("user_delete_success"));
    });
  };

  return (
    <Margin>
      {/* <div className="row">
            <div className="col-md-12"> 
     <!-- DATA TABLE --> <h3 className="title-5 m-b-35">data table</h3>*/}

      {userPermissions[3] === "1" && (
        <div className="table-data__tool">
          <div className="table-data__tool-left">
            {/* <div className="rs-select2--light rs-select2--md">
            <select className="js-select2" name="property">
              <option selected="selected">All Properties</option>
              <option value="">Option 1</option>
              <option value="">Option 2</option>
            </select>
            <div className="dropDownSelect2"></div>
          </div> */}
            {/* <div className="rs-select2--light rs-select2--sm">
            <select className="js-select2" name="time">
              <option selected="selected">Today</option>
              <option value="">3 Days</option>
              <option value="">1 Week</option>
            </select>
            <div className="dropDownSelect2"></div>
          </div> */}
            <div className="input-group show-entries">
              <div className="input-group-prepend">
                <span className="before" type="button">
                  <InlineIcon icon="zmdi:filter-list" />
                </span>
              </div>

              <Select
                options={pagination_options}
                onChange={change_pagination}
                defaultValue={pagination_options[1]}
                name="pagination"
                styles={customStyles}
                className="orangeBack"
              />

              <div className="input-group-append">
                <span className="after" type="button">
                  entries
                </span>
              </div>
            </div>
          </div>
          {userPermissions[2] === "1" && (
            <div className="table-data__tool-right">
              <button className="au-btn au-btn-icon orangeBack au-btn--small au-btn-add float-end" onClick={() => opencloseModalInsert()}>
                <InlineIcon icon="zmdi:plus" className="bi bi-align-center" />
                {t("new")}
              </button>
            </div>
          )}
        </div>
      )}
      {userPermissions[3] === "1" && (
        <div className="table-responsive table-responsive-data2">
          <table className="table table-data2">
            <thead>
              <tr>
                <th className="description-td">{t("email")}</th>
                <th className="company-td">{t("first_name")}</th>
                <th className="company-td">{t("last_name")}</th>
                <th className="actions-td"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user) => {
                return (
                  <React.Fragment key={`${user.id}fragment`}>
                    <tr className="tr-shadow" key={user.id}>
                      <td className="desc">{user.email}</td>
                      <td>
                        {/* <span className="block-email">lori@example.com</span> */}
                        {user.first_name}
                      </td>
                      <td>{user.last_name}</td>
                      <td>
                        <div className="table-data-feature">
                          {/* <button
                                className="item"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Send"
                              >
                                <Icon icon="zmdi:mail-send" />
                              </button> */}
                          {userPermissions[1] === "1" && (
                            <>
                              <button className="item orangeBack" data-tip data-for={`EditTip${user.id}`} onClick={() => selectUser(user, "edit")}>
                                <Icon icon="line-md:pencil" />
                              </button>
                              <ReactTooltip id={`EditTip${user.id}`} place="top" effect="solid">
                                {t("edit")}
                              </ReactTooltip>
                            </>
                          )}
                          {userPermissions[0] === "1" && (
                            <>
                              <button className="item orangeBack" data-tip data-for={`DeleteTip${user.id}`} onClick={() => selectUser(user, "delete")}>
                                <Icon icon="line-md:remove" />
                              </button>
                              <ReactTooltip id={`DeleteTip${user.id}`} place="top" effect="solid">
                                {t("delete")}
                              </ReactTooltip>
                            </>
                          )}
                          {/* <button
                              className="item"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="More"
                            >
                              <Icon icon="zmdi:more" />
                            </button> */}
                        </div>
                      </td>
                    </tr>
                    <tr className="spacer" key={`${user.id}/asdf`}></tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
            forcePage={force_page}
          />
        </div>
      )}

      {userPermissions[2] === "1" && (
        <Modal isOpen={modalAdd}>
          <ModalHeader>{t("add_user")}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="email">{t("email")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="email"
                onChange={handleChange}
                id="email"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                data-tip
                data-for="email"
              />
              {selecteduser.email.valid === false && (
                <ReactTooltip id="email" place="top" effect="solid">
                  {t("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("password")}</label>
              <input
                className="form-control lightgreyBack  validate-form-element"
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={handleChange}
                id="password"
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                data-tip
                data-for="password"
              />
              {selecteduser.password?.valid === false && (
                <ReactTooltip id="password" place="top" effect="solid">
                  {t("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="first_name"
                onChange={handleChange}
                id="first_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="first_name"
              />
              {selecteduser.first_name.valid === false && (
                <ReactTooltip id="first_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="last_name">{t("last_name")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="last_name"
                onChange={handleChange}
                id="last_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="last_name"
              />
              {selecteduser.last_name.valid === false && (
                <ReactTooltip id="last_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="maintenance_number">{t("maintenance_number")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="maintenance_number"
                onChange={handleChange}
                id="maintenance_number"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="maintenance_number"
              />
              {selecteduser.maintenance_number.valid === false && (
                <ReactTooltip id="maintenance_number" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="status">{t("status")}</label>
              <Select
                options={status_options}
                onChange={handle_Select_options}
                defaultValue={status_options[0]}
                styles={customStyles}
                name="status"
                id="status"
              />
            </div>
            <div className="form-group">
              <label htmlFor="auth_rol">{t("auth_rol")}</label>
              <Select
                options={authrol_options}
                onChange={handle_Select_options}
                defaultValue={authrol_options[1]}
                styles={customStyles}
                name="auth_rol"
                id="auth_rol"
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-6">{t("user_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="user_view_permission"
                    name="user_view_permission"
                    label={t("view")}
                    value={permissions["user_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_create_permission"
                    name="user_create_permission"
                    label={t("create")}
                    value={permissions["user_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_edit_permission"
                    name="user_edit_permission"
                    label={t("edit")}
                    value={permissions["user_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_delete_permission"
                    name="user_delete_permission"
                    label={t("delete")}
                    value={permissions["user_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-6">{t("client_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="client_view_permission"
                    name="client_view_permission"
                    label={t("view")}
                    value={permissions["client_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_create_permission"
                    name="client_create_permission"
                    label={t("create")}
                    value={permissions["client_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_edit_permission"
                    name="client_edit_permission"
                    label={t("edit")}
                    value={permissions["client_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_delete_permission"
                    name="client_delete_permission"
                    label={t("delete")}
                    value={permissions["client_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-6">{t("report_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="report_view_permission"
                    name="report_view_permission"
                    label={t("view")}
                    value={permissions["report_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_create_permission"
                    name="report_create_permission"
                    label={t("create")}
                    value={permissions["report_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_edit_permission"
                    name="report_edit_permission"
                    label={t("edit")}
                    value={permissions["report_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_delete_permission"
                    name="report_delete_permission"
                    label={t("delete")}
                    value={permissions["report_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-success"
              onClick={() => {
                let valid = true;
                Object.keys(selecteduser).map((index) => {
                  if (selecteduser[index].valid === false) valid = false;
                  return true;
                });
                if (valid) {
                  addFunction();
                } else {
                  toast.error(t("invalid_form"));
                }
              }}
            >
              {t("add")}
            </button>
            <button className="btn btn-danger" onClick={() => opencloseModalInsert()}>
              {t("cancel")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {userPermissions[1] === "1" && (
        <Modal isOpen={modalEdit}>
          <ModalHeader>{t("edit_report")}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="new_email">{t("email")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="new_email"
                onChange={handleChange}
                value={selecteduser && selecteduser.new_email?.value}
                id="new_email"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                data-tip
                data-for="new_email"
              />
              {selecteduser.new_email?.valid === false && (
                <ReactTooltip id="new_email" place="top" effect="solid">
                  {t("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("password")}</label>
              <input
                className="form-control lightgreyBack"
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={handleChange}
                placeholder={t("leave_empty_to_keep_previous")}
                id="password"
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                data-tip
                data-for="password"
              />
              {selecteduser.password?.valid === false && (
                <ReactTooltip id="password" place="top" effect="solid">
                  {t("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="first_name"
                onChange={handleChange}
                value={selecteduser && selecteduser.first_name.value}
                id="first_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="first_name"
              />
              {selecteduser.first_name.valid === false && (
                <ReactTooltip id="first_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="last_name">{t("last_name")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="last_name"
                onChange={handleChange}
                value={selecteduser && selecteduser.last_name.value}
                id="last_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="last_name"
              />
              {selecteduser.last_name.valid === false && (
                <ReactTooltip id="last_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="maintenance_number">{t("maintenance_number")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="maintenance_number"
                onChange={handleChange}
                value={selecteduser && selecteduser.maintenance_number.value}
                id="maintenance_number"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="maintenance_number"
              />
              {selecteduser.maintenance_number.valid === false && (
                <ReactTooltip id="maintenance_number" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="status">{t("status")}</label>
              <Select
                options={status_options}
                onChange={handle_Select_options}
                defaultValue={status_options.find((type) => {
                  return type.value === selecteduser.status.value;
                })}
                styles={customStyles}
                name="status"
                id="status"
              />
            </div>
            <div className="form-group">
              <label htmlFor="auth_rol">{t("auth_rol")}</label>
              <Select
                options={authrol_options}
                onChange={handle_Select_options}
                defaultValue={authrol_options.find((type) => {
                  return type.value === selecteduser.auth_rol.value;
                })}
                styles={customStyles}
                name="auth_rol"
                id="auth_rol"
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-4">{t("user_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="user_view_permission"
                    name="user_view_permission"
                    label={t("view")}
                    value={permissions["user_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_create_permission"
                    name="user_create_permission"
                    label={t("create")}
                    value={permissions["user_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_edit_permission"
                    name="user_edit_permission"
                    label={t("edit")}
                    value={permissions["user_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="user_delete_permission"
                    name="user_delete_permission"
                    label={t("delete")}
                    value={permissions["user_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-6">{t("client_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="client_view_permission"
                    name="client_view_permission"
                    label={t("view")}
                    value={permissions["client_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_create_permission"
                    name="client_create_permission"
                    label={t("create")}
                    value={permissions["client_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_edit_permission"
                    name="client_edit_permission"
                    label={t("edit")}
                    value={permissions["client_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="client_delete_permission"
                    name="client_delete_permission"
                    label={t("delete")}
                    value={permissions["client_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-6">{t("report_permissions")}</div>
              </div>
              <div className="row justify-content-center">
                <div className="col-5">
                  <SwitchButton
                    id="report_view_permission"
                    name="report_view_permission"
                    label={t("view")}
                    value={permissions["report_view_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_create_permission"
                    name="report_create_permission"
                    label={t("create")}
                    value={permissions["report_create_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_edit_permission"
                    name="report_edit_permission"
                    label={t("edit")}
                    value={permissions["report_edit_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
                <div className="col-5">
                  <SwitchButton
                    id="report_delete_permission"
                    name="report_delete_permission"
                    label={t("delete")}
                    value={permissions["report_delete_permission"]}
                    onChange={handle_permission_checkbox_cLick}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => editFunction()}>
              {t("edit")}
            </button>
            <button className="btn btn-danger" onClick={() => opencloseModalEdit()}>
              {t("cancel")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {userPermissions[0] === "1" && (
        <Modal isOpen={modalDelete}>
          <ModalBody>
            {t("delete_confirmation", { entity: t("user") })} {selecteduser && selecteduser.email}?{/* {selectedreport && selectedreport.company_name}? */}
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => delFunction()}>
              {t("yes")}
            </button>
            <button className="btn btn-secondary" onClick={() => opencloseModalDelete()}>
              {t("no")}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Margin>
  );
}

export default UserCRUD;
