import React, { useState, useRef, useEffect, Component } from "react";
import Cookies from "js-cookie";
import QRCode from "qrcode";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import jsQR from "jsqr";
import ReactTooltip from "react-tooltip";
import { Icon, InlineIcon } from "@iconify/react";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactPaginate from "react-paginate";

import { Margin } from "../custom-components/checkbox";
import QRScan from "../custom-components/qrscan";
import "./App.css";
import { data_access } from "../data-access";

function ReportCRUD() {
  const userPermissions = JSON.parse(Cookies.get("userData"))["report"];
  const { t } = useTranslation();
  //client pagination
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientFilter, setClientFilter] = useState("");
  //report pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [reportFilter, setReportFilter] = useState("");
  const [force_page, set_force_page] = useState(-1);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemOffset, setItemOffset] = useState(0);
  const [reportRaw, setReportRaw] = useState([]);

  const QrRef = useRef(null);
  const [imageURL, setImageURL] = useState("");
  const [watchingState, setWatchingState] = useState(false);
  const [qrData, setQrData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [wyzard_select_type, set_wyzard_select_type] = useState(false);
  const [wyzard_client_data, set_wyzard_client_data] = useState(false);
  const [wyzard_report_gas_density_new, set_wyzard_report_gas_density_new] = useState(false);
  const [wyzard_report_gas_density_existent, set_wyzard_report_gas_density_existent] = useState(false);
  const [wyzard_radiator_and_contactor_adjustment, set_wyzard_radiator_and_contactor_adjustment] = useState(false);
  const [modal_import_client, set_modal_import_client] = useState(false);
  const [_import_client, set__import_client] = useState({});
  const wyzard_client_data_body = useRef(null);
  const report_types = [
    { value: "report_gas_density_new", label: t("report_gas_density_new") },
    {
      value: "report_gas_density_existent",
      label: t("report_gas_density_existent"),
    },
    {
      value: "radiator_and_contactor_adjustment",
      label: t("radiator_and_contactor_adjustment"),
    },
  ];
  const [selected_client, set_selected_client] = useState({
    id: "",
    company_name: { value: "", valid: false },
    first_name: { value: "", valid: false },
    lastname: { value: "", valid: false },
    phone: { value: "", valid: false },
    mobile: { value: "", valid: false },
    email: { value: "", valid: false },
    vat_number: { value: "", valid: false },
    website: { value: "", valid: false },
    city: { value: "", valid: false },
    adress: { value: "", valid: false },
    house_number: { value: "", valid: false },
    postcode: { value: "", valid: false },
  });
  const [add_report, set_add_report] = useState({
    brand_n_type: { value: "", valid: false },
    serialnumber: { value: "", valid: false },
    calibration_date: { value: "", valid: false },
    report_type: { value: "", valid: false },
  });
  const pagination_options = [
    { value: "2", label: "2" },
    { value: "4", label: "4" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
  ];

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "1px solid #ffffff",
      background: "#df8702",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "none",
      background: "#df8702",
    }),
    singleValue: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
    }),
    menuList: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      background: "#3d3d3d",
    }),
  };
  const handleClientInputsChange = (e) => {
    const { name, value } = e.target;

    if (e.target.attributes["pattern"]) {
      const pattern = e.target.attributes["pattern"].value;
      let re = new RegExp(pattern);
      var valid = re.test(value);
      if (valid) {
        e.target.classList.remove("validate-form-element");
      } else {
        e.target.classList.add("validate-form-element");
      }
      // console.log(pattern, valid, value);
    }

    set_selected_client((prevState) => ({
      ...prevState,
      [name]: { value: value, valid: valid },
    }));
    // console.log(selected_client);
  };
  const handle_report_inputs = (e) => {
    const { name, value } = e.target;

    // console.log(e);
    if (e.target.attributes["pattern"]) {
      const pattern = e.target.attributes["pattern"].value;
      let re = new RegExp(pattern);
      var valid = re.test(value);
      if (valid) {
        e.target.classList.remove("validate-form-element");
      } else {
        e.target.classList.add("validate-form-element");
      }
      // console.log(pattern, valid, value);
    }
    set_add_report((prevState) => ({
      ...prevState,
      [name]: { value: value, valid: valid },
    }));
    // console.log(add_report);
  };
  const handleReportMultiInputs = (index, e) => {
    const { name, value } = e.target;

    // console.log(e);
    if (e.target.attributes["pattern"]) {
      const pattern = e.target.attributes["pattern"].value;
      let re = new RegExp(pattern);
      var valid = re.test(value);
      if (valid) {
        e.target.classList.remove("validate-form-element");
      } else {
        e.target.classList.add("validate-form-element");
      }
      // console.log(pattern, valid, value);
    }
    set_add_report((prevState) => {
      let tmp = Object.assign({}, prevState);
      tmp[name]["value"][index]["value"] = value;
      tmp[name]["value"][index]["valid"] = valid;
      let totalvalid = true;
      for (let i = 0; i < tmp[name]["value"].length; i++) {
        if (tmp[name].value[i].valid === false) {
          totalvalid = false;
          break;
        }
      }
      tmp[name]["valid"] = totalvalid;
      // console.log(tmp, name, totalvalid);
      return tmp;
    });
    // console.log(add_report);
  };
  const handleClientFilter = (e) => {
    // console.log(e.target.value);
    setClientFilter(e.target.value);
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * parseInt(itemsPerPage)) % reportRaw.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const change_pagination = (v, e) => {
    setItemsPerPage(v.value);
    setItemOffset(0);
    set_force_page(0);
  };
  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL("test code generation");
      setImageURL(response);
    } catch (error) {
      console.error(error);
    }
  };
  const opencloseModals = (callable, value) => {
    callable(!value);
  };

  const onFind = (value) => {
    setWatchingState(false);
    setQrData(value);
  };
  const readQrCode = (fileReaderResult) => {
    let img = new Image();
    img.src = fileReaderResult;
    img.onload = () => {
      let imgWidth = img.width;
      let imgHeight = img.height;
      let c = document.createElement("canvas");
      c.width = imgWidth;
      c.height = imgHeight;
      let ctx = c.getContext("2d");
      ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
      let imageData = ctx.getImageData(0, 0, imgWidth, imgHeight);
      let code = jsQR(imageData.data, imgWidth, imgHeight, {
        inversionAttempts: "dontInvert",
      });
      if (code) {
        // console.log("Found QR code", code.data.split(/[;\r]|[\r\n]/).filter(Boolean));
        let data = [];
        console.log(data);
        code.data.split("\r\n").forEach((val) => {
          let temp = val.split(";");
          data[temp[0]] = temp[1];
        });
        // console.log(data);
        setQrData(data);
      } else {
        setQrData("");
      }
    };
  };
  const onQRInputChange = (element) => {
    let fr = new FileReader();
    fr.onload = () => readQrCode(fr.result);
    fr.readAsDataURL(element.currentTarget.files[0]);
  };

  const selectReport = (report, action) => {
    let temp = {};
    Object.keys(report).map((index) => {
      try {
        console.log(report[index]);
        let o = JSON.parse(report[index]);
        if (o && typeof o === "object") {
          let obj = [];
          for (let i = 0; i < o.length; i++) {
            obj.push({ valid: true, value: o[i] });
          }
          temp[`${index}[]`] = { value: obj, valid: true };
        } else {
          temp[index] = { value: report[index], valid: true };
        }
      } catch (e) {
        temp[index] = { value: report[index], valid: true };
      }
      return true;
    });
    // console.log(temp);
    switch (action) {
      case "edit":
        set_add_report(temp);
        findReportTypeModal(temp.report_type.value);

        break;
      case "delete":
        break;

      default:
        break;
    }
  };
  const getAllClients = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("email", userData.email);
    f.append("token", userData.token);
    f.append("serie", userData.serie);
    f.append("METHOD", "GET");
    try {
      await axios.post(`${data_access.API_URL}client.php`, f).then((response) => {
        setClients(response.data.slice(0, 5));
      });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(t("insufficient_permissions"));
      } else {
        console.error(e);
      }
    }
  };
  const doGetReportList = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("authemail", userData.email);
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("METHOD", "GET");
    try {
      await axios.post(`${data_access.API_URL}report.php`, f).then((response) => {
        setReportRaw(response.data);
        // console.log(response.data);
      });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(t("insufficient_permissions"));
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    doGetReportList();
  }, []);
  useEffect(() => {
    let filteredData = clients.filter((o) => Object.keys(o).some((k) => o[k].toLowerCase().includes(clientFilter.toLowerCase())));
    setFilteredClients(filteredData.slice(0, 5));
  }, [clients, clientFilter]);
  useEffect(() => {
    if (reportRaw.length > 0) {
      // Fetch items from another resources.
      const endOffset = itemOffset + parseInt(itemsPerPage);
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      // console.log(data);

      let filteredData = reportRaw.filter((o) => Object.keys(o).some((k) => o[k].toLowerCase().includes(reportFilter.toLowerCase())));
      setCurrentItems(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
      set_force_page(endOffset / parseInt(itemsPerPage) - 1);
    }
  }, [itemOffset, itemsPerPage, reportRaw, reportFilter]);

  const findReportTypeModal = (value) => {
    switch (value) {
      case "report_gas_density_new":
        opencloseModals(set_wyzard_report_gas_density_new, wyzard_report_gas_density_new);
        break;
      case "report_gas_density_existent":
        opencloseModals(set_wyzard_report_gas_density_existent, wyzard_report_gas_density_existent);
        break;

      case "radiator_and_contactor_adjustment":
        opencloseModals(set_wyzard_radiator_and_contactor_adjustment, wyzard_radiator_and_contactor_adjustment);
        break;
      default:
        break;
    }
  };

  const addReport = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("authemail", userData.email);
    f.append("METHOD", "POST");
    f.append("status", "open");
    Object.keys(add_report).map((index) => {
      // console.log(`${index} => ${add_report[index].value}`);
      if (typeof index.split("[]")[1] !== "undefined") {
        Object.keys(add_report[index].value).map((j) => {
          // console.log(add_report[index].value[j].value, index, j);
          f.append(index, add_report[index].value[j].value);
          return true;
        });
      } else {
        f.append(index, add_report[index]["value"]);
      }
      return true;
    });
    f.append("company_name", selected_client.company_name.value);
    f.append("first_name", selected_client.first_name.value);
    f.append("lastname", selected_client.lastname.value);
    f.append("phone", selected_client.phone.value);
    f.append("mobile", selected_client.mobile.value);
    f.append("email", selected_client.email.value);
    f.append("website", selected_client.website.value);
    f.append("vat_number", selected_client.vat_number.value);
    f.append("city", selected_client.city.value);
    f.append("adress", selected_client.adress.value);
    f.append("house_number", selected_client.house_number.value);
    f.append("postcode", selected_client.postcode.value);

    await axios
      .post(`${data_access.API_URL}report.php`, f)
      .then((response) => {
        console.log(response);

        setReportRaw(reportRaw.concat(response.data));
        findReportTypeModal(add_report.report_type.value);
        toast.success(t("client_add_success"));
      })
      .catch((e) => {
        if (e.response.status === 403) {
          toast.error(t("insufficient_permissions"));
        } else {
          console.error(e);
        }
      });
  };
  const editReport = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("authemail", userData.email);
    f.append("METHOD", "PUT");
    f.append("status", "open");
    Object.keys(add_report).map((index) => {
      // console.log(`${index} => ${add_report[index].value}`);
      if (typeof index.split("[]")[1] !== "undefined") {
        Object.keys(add_report[index].value).map((j) => {
          // console.log(add_report[index].value[j].value, index, j);
          f.append(index, add_report[index].value[j].value);
          return true;
        });
      } else {
        f.append(index, add_report[index]["value"]);
      }
    });
    f.append("company_name", add_report.company_name.value);
    f.append("first_name", add_report.first_name.value);
    f.append("lastname", add_report.lastname.value);
    f.append("phone", add_report.phone.value);
    f.append("mobile", add_report.mobile.value);
    f.append("email", add_report.email.value);
    f.append("website", add_report.website.value);
    f.append("vat_number", add_report.vat_number.value);
    f.append("city", add_report.city.value);
    f.append("adress", add_report.adress.value);
    f.append("house_number", add_report.house_number.value);
    f.append("postcode", add_report.postcode.value);
    await axios
      .post(`${data_access.API_URL}report.php`, f)
      .then((response) => {
        // console.log(response);
        let newData = reportRaw;
        newData.forEach((report) => {
          if (report.id === response.data.id) {
            Object.keys(report).map((index) => {
              report[index] = response.data[index];
              return true;
            });
          }
        });
        setReportRaw(newData);
        findReportTypeModal(add_report.report_type.value);
        toast.success(t("report_edited_successfully"));
      })
      .catch((e) => {
        if (e.response.status === 403) {
          toast.error(t("insufficient_permissions"));
        } else {
          console.error(e);
        }
      });
  };
  return (
    <>
      <div>
        <Margin margin={50}>
          {/* {false && (
            <>
              <button
                className="btn btn-success"
                onClick={() => generateQrCode()}
              >
                generate qr image
              </button>
              <br />
            </>
          )}
          {imageURL ? <img src={imageURL} alt="img" /> : null}

          {watchingState ? (
            <QRScan onFind={onFind} ref={QrRef} />
          ) : (
            <>
              <button
                className="btn btn-success"
                onClick={() => setWatchingState(true)}
              >
                Scan
              </button>
              <h4>value: {qrData}</h4>
            </>
          )}
          {false && (
            <div>
              <h1>Upload and Display Image using React Hook's</h1>
              {selectedImage && (
                <div>
                  <img
                    alt="not fount"
                    width={"250px"}
                    src={URL.createObjectURL(selectedImage)}
                  />
                  <br />
                  <button
                    className="btn btn-default"
                    onClick={() => setSelectedImage(null)}
                  >
                    Remove
                  </button>
                </div>
              )} 
              <br />

              <br />
              <input type="file" onChange={onQRInputChange} />
            </div>
          )}
          */}

          {userPermissions[3] === "1" && (
            <div className="table-data__tool">
              <div className="table-data__tool-left col">
                <div className="input-group show-entries">
                  <div className="input-group-prepend">
                    <span className="before" type="button">
                      <InlineIcon icon="zmdi:filter-list" />
                    </span>
                  </div>

                  <Select
                    options={pagination_options}
                    onChange={change_pagination}
                    defaultValue={pagination_options[1]}
                    name="pagination"
                    styles={customStyles}
                    className="orangeBack"
                  />

                  <div className="input-group-append">
                    <span className="after" type="button">
                      entries
                    </span>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control lightgreyBack"
                    placeholder="Filter"
                    aria-label="Filter"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setReportFilter(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text orangeBack" id="basic-addon1">
                      <InlineIcon style={{ cursor: "pointer" }} className="orangeBack" icon="ic:outline-manage-search" height="24" />
                    </span>
                  </div>
                </div>
              </div>

              {userPermissions[2] === "1" && (
                <div className="table-data__tool-right col">
                  <button
                    className="au-btn au-btn-icon orangeBack au-btn--small au-btn-add float-end"
                    onClick={() => {
                      set_add_report({
                        brand_n_type: { value: "", valid: false },
                        serialnumber: { value: "", valid: false },
                        calibration_date: { value: "", valid: false },
                        report_type: { value: report_types[0].value, valid: true },
                      });
                      opencloseModals(set_wyzard_select_type, wyzard_select_type);
                    }}
                  >
                    <InlineIcon icon="zmdi:plus" className="bi bi-align-center" />
                    {t("new")}
                  </button>
                </div>
              )}
            </div>
          )}
          {userPermissions[3] === "1" && (
            <div className="table-responsive table-responsive-data2">
              <table className="table table-data2">
                <thead>
                  <tr>
                    <th className="company-td">{t("company_name")}</th>
                    <th>{t("adress")}</th>
                    <th>{t("type")}</th>
                    <th>{t("status")}</th>
                    <th>{t("creation_date")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((report) => {
                    return (
                      <React.Fragment key={`${report.id}fragment`}>
                        <tr className="tr-shadow" key={report.id}>
                          <td>{report.company_name}</td>
                          <td className="desc">
                            {report.adress} {report.house_number}, {report.city} {report.postcode}
                          </td>
                          <td>
                            {
                              report_types.find((type) => {
                                return type.value === report.report_type;
                              }).label
                            }
                          </td>
                          <td className={report.status}>{t(report.status)}</td>
                          <td></td>
                          <td>
                            <div className="table-data-feature">
                              {userPermissions[1] === "1" && report.status === "open" && (
                                <>
                                  <button className="item" data-tip data-for={`EditTip${report.id}`} onClick={() => selectReport(report, "edit")}>
                                    <Icon icon="line-md:pencil" />
                                  </button>
                                  <ReactTooltip id={`EditTip${report.id}`} place="top" effect="solid">
                                    {t("edit")}
                                  </ReactTooltip>
                                </>
                              )}
                              {/*userPermissions[0] === "1" && (
                                <>
                                  <button
                                    className="item"
                                    data-tip
                                    data-for={`DeleteTip${user.id}`}
                                    onClick={() => selectUser(user, "delete")}
                                  >
                                    <Icon icon="line-md:remove" />
                                  </button>
                                  <ReactTooltip
                                    id={`DeleteTip${user.id}`}
                                    place="top"
                                    effect="solid"
                                  >
                                    {t("delete")}
                                  </ReactTooltip>
                                </>
                              )*/}
                            </div>
                          </td>
                        </tr>
                        <tr className="spacer" key={`${report.id}/spacer`}></tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
                forcePage={force_page}
              />
            </div>
          )}
        </Margin>
      </div>
      <Modal isOpen={wyzard_select_type} className="backgroundcolor">
        <ModalHeader cssModule={{ "modal-title": "larger-letter" }} toggle={() => opencloseModals(set_wyzard_select_type, wyzard_select_type)}>
          <div style={{ padding: "5px" }}>{t("measurement_equipment")}</div>
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="brand_n_type">{t("brand_n_type")}</label>
            <input
              className="form-control lightgreyBack validate-form-element"
              type="text"
              name="brand_n_type"
              id="brand_n_type"
              onChange={handle_report_inputs}
              pattern="^[a-zA-Z0-9\s]+$"
              data-tip
              data-for="brand_n_type"
            />
            {add_report.brand_n_type.valid === false && (
              <ReactTooltip id="brand_n_type" place="top" effect="solid">
                {t("^[a-zA-Z0-9\\s]+$")}
              </ReactTooltip>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="serialnumber">{t("serialnumber")}</label>
            <input
              className="form-control lightgreyBack validate-form-element"
              type="text"
              name="serialnumber"
              id="serialnumber"
              onChange={handle_report_inputs}
              pattern="^\w+$"
              data-tip
              data-for="serialnumber"
            />
            {add_report.serialnumber.valid === false && (
              <ReactTooltip id="serialnumber" place="top" effect="solid">
                {t("^\\w+$")}
              </ReactTooltip>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="calibration_date">{t("calibration_date")}</label>
            <input
              className="form-control lightgreyBack validate-form-element"
              type="date"
              name="calibration_date"
              id="calibration_date"
              onChange={handle_report_inputs}
              pattern="^\d{4}-\d{2}-\d{2}$"
              data-tip
              data-for="calibration_date"
            />
            {add_report.calibration_date.valid === false && (
              <ReactTooltip id="calibration_date" place="top" effect="solid">
                {t("^\\d{4}-\\d{2}-\\d{2}$")}
              </ReactTooltip>
            )}
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="report_type">{t("select_report_type")}</label>
            <Select
              options={report_types}
              onChange={(v, e) => {
                const name = e.name;
                const value = v.value;
                set_add_report((prevState) => ({
                  ...prevState,
                  [name]: { value: value, valid: true },
                }));
                // console.log(add_report);
              }}
              defaultValue={report_types[0]}
              styles={customStyles}
              name="report_type"
              id="report_type"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              // console.log(e);
              let valid = true;
              Object.keys(add_report).map((index) => {
                if (add_report[index]["valid"] === false) valid = false;
                return true;
              });
              if (valid) {
                switch (add_report.report_type.value) {
                  case "report_gas_density_new":
                    set_add_report((prevState) => ({
                      ...prevState,
                      ["executed_at_5_bar"]: { value: "", valid: false },
                      ["supply_start_time"]: { value: "", valid: false },
                      ["supply_end_time"]: { value: "", valid: false },
                      ["pressure"]: { value: "", valid: false },
                      ["pressure_drop"]: { value: "", valid: false },
                      ["conclussion"]: { value: "", valid: false },
                      ["gas_used"]: { value: "", valid: false },
                      ["instalation_start_time"]: { value: "", valid: false },
                      ["instalation_end_time"]: { value: "", valid: false },
                      ["pressure_under_work_pressure"]: { value: "", valid: false },
                      ["pressure_drop_under_work_pressure"]: { value: "", valid: false },
                      ["under_work_pressure_conclussion"]: { value: "", valid: false },
                    }));
                    break;

                  case "report_gas_density_existent":
                    set_add_report((prevState) => ({
                      ...prevState,
                      ["supply_start_time"]: { value: "", valid: false },
                      ["supply_end_time"]: { value: "", valid: false },
                      ["pressure"]: { value: "", valid: false },
                      ["pressure_drop"]: { value: "", valid: false },
                      ["conclussion"]: { value: "", valid: false },
                      ["instalation_start_time"]: { value: "", valid: false },
                      ["instalation_end_time"]: { value: "", valid: false },
                      ["pressure_under_work_pressure"]: { value: "", valid: false },
                      ["pressure_drop_under_work_pressure"]: { value: "", valid: false },
                      ["under_work_pressure_conclussion"]: { value: "", valid: false },
                    }));
                    break;

                  case "radiator_and_contactor_adjustment":
                    set_add_report((prevState) => ({
                      ...prevState,
                      ["location[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["type[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["length[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["height[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["capacity[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["adjustment_mode[]"]: {
                        valid: false,
                        value: [
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                          { value: "", valid: false },
                        ],
                      },
                      ["supply_temperature"]: { value: "", valid: false },
                      ["return_temperature"]: { value: "", valid: false },
                      ["pump_min_capacity"]: { value: "", valid: false },
                      ["pump_max_capacity"]: { value: "", valid: false },
                      ["total_capacity"]: { value: "", valid: false },
                    }));
                    break;

                  default:
                    break;
                }
                console.log(add_report);

                opencloseModals(set_wyzard_select_type, wyzard_select_type);
                opencloseModals(set_wyzard_client_data, wyzard_client_data);
              } else {
                toast.error(t("invalid_form"));
              }
            }}
          >
            {t("next")}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              opencloseModals(set_wyzard_select_type, wyzard_select_type);
            }}
          >
            {t("cancel")}
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal_import_client} size="lg" className="backgroundcolor">
        <ModalHeader cssModule={{ "modal-title": "larger-letter" }} toggle={() => opencloseModals(set_modal_import_client, modal_import_client)}>
          <div className="form-group">
            <input className="form-control" placeholder={t("filter")} type="text" name="subject" id="subject" onChange={handleClientFilter} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive table-responsive-data2">
            <table className="table table-data2">
              <thead>
                <tr>
                  <th>{t("company_name")}</th>
                  <th>{t("adress")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((client) => {
                  return (
                    <React.Fragment key={`${client.id}fragment`}>
                      <tr
                        className="tr-shadow select-hover"
                        key={client.id}
                        onClick={(e) => {
                          [].forEach.call(e.target.parentElement.parentElement.children, function (tr) {
                            if (tr.classList.contains("selected-client")) {
                              tr.classList.remove("selected-client");
                            }
                          });
                          e.target.parentElement.nodeName === "TR"
                            ? e.target.parentElement.classList.add("selected-client")
                            : e.target.parentElement.classList.add("");
                          set__import_client(client);
                        }}
                      >
                        <td>
                          <span className="status--process">{client.company_name}</span>
                        </td>
                        <td>
                          {client.adress} {client.house_number}, {client.city} {client.postcode}
                        </td>
                      </tr>
                      <tr className="spacer" key={`${client.id}/asdf`}></tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                Object.keys(_import_client).map((index) => {
                  set_selected_client((prevState) => ({
                    ...prevState,
                    [index]: { value: _import_client[index], valid: true },
                  }));
                  return true;
                });
                for (let i = 0; i < wyzard_client_data_body.current.children.length; i++) {
                  wyzard_client_data_body.current.children[i].children[1].classList.remove("validate-form-element");
                }
                opencloseModals(set_modal_import_client, modal_import_client);
              }}
            >
              <Icon icon="line-md:confirm" />
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={wyzard_client_data} className="backgroundcolor">
        <ModalHeader cssModule={{ "modal-title": "larger-letter" }}>
          <div style={{ padding: "5px" }}>
            {t("client_data")}

            <button
              style={{
                display: `inline-block`,
                position: `absolute`,
                right: `12px`,
                top: `12px`,
              }}
              className="btn  btn-primary"
              onClick={async () => {
                await getAllClients();
                opencloseModals(set_modal_import_client, modal_import_client);
              }}
            >
              <InlineIcon icon="line-md:check-list-3-twotone" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div ref={wyzard_client_data_body}>
            <div className="form-group">
              <label htmlFor="company_name">{t("company_name")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="company_name"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.company_name.value}
                id="company_name"
                pattern="^[-.a-zA-Z0-9\s]+$"
                data-tip
                data-for="company_name"
              />
              {selected_client.company_name.valid === false && (
                <ReactTooltip id="company_name" place="top" effect="solid">
                  {t("^[-.a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="first_name"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.first_name.value}
                id="first_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="first_name"
              />
              {selected_client.first_name.valid === false && (
                <ReactTooltip id="first_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="lastname">{t("lastname")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="lastname"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.lastname.value}
                id="lastname"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="lastname"
              />
              {selected_client.lastname.valid === false && (
                <ReactTooltip id="lastname" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t("phone")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="phone"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.phone.value}
                id="phone"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="phone"
              />
              {selected_client.phone.valid === false && (
                <ReactTooltip id="phone" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="mobile">{t("mobile")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="mobile"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.mobile.value}
                id="mobile"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="mobile"
              />
              {selected_client.mobile.valid === false && (
                <ReactTooltip id="mobile" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{t("email")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="email"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.email.value}
                id="email"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                data-tip
                data-for="email"
              />
              {selected_client.email.valid === false && (
                <ReactTooltip id="email" place="top" effect="solid">
                  {t("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="vat_number">{t("vat_number")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="vat_number"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.vat_number.value}
                id="vat_number"
                pattern="^[\w\s]{5,}$"
                data-tip
                data-for="vat_number"
              />
              {selected_client.vat_number.valid === false && (
                <ReactTooltip id="vat_number" place="top" effect="solid">
                  {t("^[\\w\\s]{5,}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="website">{t("website")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="website"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.website.value}
                id="website"
                pattern="^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}$"
                data-tip
                data-for="website"
              />
              {selected_client.website.valid === false && (
                <ReactTooltip id="website" place="top" effect="solid">
                  {t("^(https?:\\/\\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="city">{t("city")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="city"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.city.value}
                id="city"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="city"
              />
              {selected_client.city.valid === false && (
                <ReactTooltip id="city" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="adress">{t("adress")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="adress"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.adress.value}
                id="adress"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="adress"
              />
              {selected_client.adress.valid === false && (
                <ReactTooltip id="adress" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="house_number">{t("house_number")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="house_number"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.house_number.value}
                id="house_number"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="house_number"
              />
              {selected_client.house_number.valid === false && (
                <ReactTooltip id="house_number" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="postcode">{t("postcode")}</label>
              <input
                className="form-control validate-form-element"
                type="text"
                name="postcode"
                onChange={handleClientInputsChange}
                value={selected_client && selected_client.postcode.value}
                id="postcode"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="postcode"
              />
              {selected_client.postcode.valid === false && (
                <ReactTooltip id="postcode" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              // console.log(selected_client);
              let valid = true;
              Object.keys(selected_client).map((index) => {
                if (selected_client[index]["valid"] === false) valid = false;
                return true;
              });
              if (valid) {
                opencloseModals(set_wyzard_client_data, wyzard_client_data);
                findReportTypeModal(add_report.report_type.value);
              } else {
                toast.error(t("invalid_form"));
              }
            }}
          >
            {t("next")}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              set_selected_client({
                id: "",
                company_name: "",
                first_name: "",
                lastname: "",
                phone: "",
                mobile: "",
                email: "",
                vat_number: "",
                website: "",
                city: "",
                adress: "",
                house_number: "",
                postcode: "",
              });
              opencloseModals(set_wyzard_client_data, wyzard_client_data);
            }}
          >
            {t("cancel")}
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={wyzard_report_gas_density_new} size="lg" className="backgroundcolor">
        <ModalHeader
          toggle={() => opencloseModals(set_wyzard_report_gas_density_new, wyzard_report_gas_density_new)}
          cssModule={{ "modal-title": "larger-letter" }}
        >
          {t("report_gas_density_new")}
        </ModalHeader>
        <ModalBody>
          <h5>{t("gas_supply")}</h5>
          <hr />
          <p className="fw-bold">{t("strength_test")}</p>
          <div className="row">
            <div className="col-3">{t("executed_with_5_bar_air")}</div>
            <div className="col-2">
              <select
                name="executed_at_5_bar"
                className={`styled-select ${add_report.executed_at_5_bar?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                pattern="^[0|1]$"
                value={add_report.executed_at_5_bar && add_report.executed_at_5_bar.value !== "" ? add_report.executed_at_5_bar.value : -1}
                data-tip
                data-for="executed_at_5_bar"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="1">{t("yes")}</option>
                <option value="0">{t("no")}</option>
              </select>
              {(add_report.executed_at_5_bar === undefined || add_report.executed_at_5_bar?.valid === false) && (
                <ReactTooltip id="executed_at_5_bar" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <br />
          <p className="fw-bold">{t("gas_tightness_test_using_air")}</p>
          <div className="row">
            <div className="col-2">{t("start_time")}</div>
            <div className={`col-4 input-group custom-input`} style={{ width: "auto" }}>
              <input
                type="time"
                name="supply_start_time"
                onChange={handle_report_inputs}
                className={`${add_report.supply_start_time?.valid ? "" : "validate-form-element"}`}
                value={!!add_report.supply_start_time ? add_report.supply_start_time.value : undefined}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="supply_start_time"
              />
              {(add_report.supply_start_time === undefined || add_report.supply_start_time?.valid === false) && (
                <ReactTooltip id="supply_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-2">{t("end_time")}</div>
            <div className={`col-4 input-group custom-input`} style={{ width: "auto" }}>
              <input
                type="time"
                name="supply_end_time"
                className={`${add_report.supply_end_time?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.supply_end_time ? add_report.supply_end_time.value : undefined}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="supply_end_time"
              />
              {(add_report.supply_end_time === undefined || add_report.supply_end_time?.valid === false) && (
                <ReactTooltip id="supply_end_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-2">{t("pressure")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="text"
                name="pressure"
                className={`${add_report.pressure?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.pressure ? add_report.pressure.value : undefined}
                pattern="^\d+$"
                data-tip
                data-for="pressure"
              />
              {(add_report.pressure === undefined || add_report.pressure?.valid === false) && (
                <ReactTooltip id="pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("minimun_of_125_hPa")}</div>
            <button className="col-1">
              <Icon icon="bi:qr-code-scan" />
            </button>
          </div>
          <div className="row">
            <div className="col-2">{t("pressure_drop")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="text"
                name="pressure_drop"
                className={`${add_report.pressure_drop?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.pressure_drop ? add_report.pressure_drop.value : undefined}
                pattern="^\d+$"
                data-tip
                data-for="pressure_drop"
              />
              {(add_report.pressure_drop === undefined || add_report.pressure_drop?.valid === false) && (
                <ReactTooltip id="pressure_drop" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("pressure_drop_not_allowed")}</div>
          </div>
          <div className="row">
            <div className="col-2">{t("conclusion")}</div>
            <div className="col-3">
              <select
                name="conclussion"
                className={`styled-select ${add_report.conclussion?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={add_report.conclussion && add_report.conclussion.value !== "" ? add_report.conclussion.value : -1}
                pattern="^[0|1]$"
                data-tip
                data-for="conclussion"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="1">{t("it_is")}</option>
                <option value="0">{t("it_is_not")}</option>
              </select>
              {(add_report.conclussion === undefined || add_report.conclussion?.valid === false) && (
                <ReactTooltip id="conclussion" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-5">{t("gastight")}</div>
          </div>
          <br />
          <h5>{t("gas_installation")}</h5>
          <hr />
          <div className="row">
            <div className="col-3">{t("gas_tightness_test_with")}</div>
            <div className="col-2">
              <select
                name="gas_used"
                className={`styled-select ${add_report.gas_used?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={add_report.gas_used && add_report.gas_used.value !== "" ? add_report.gas_used.value : -1}
                pattern="^gas|air$"
                data-tip
                data-for="gas_used"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="gas">{t("gas")}</option>
                <option value="air">{t("air")}</option>
              </select>
              {(add_report.gas_used === undefined || add_report.gas_used?.valid === false) && (
                <ReactTooltip id="gas_used" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-2">{t("start_time")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="time"
                name="instalation_start_time"
                onChange={handle_report_inputs}
                value={!!add_report.instalation_start_time ? add_report.instalation_start_time.value : undefined}
                className={`${add_report.instalation_start_time?.valid ? "" : "validate-form-element"}`}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="instalation_start_time"
              />
              {(add_report.instalation_start_time === undefined || add_report.instalation_start_time?.valid === false) && (
                <ReactTooltip id="instalation_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-2">{t("end_time")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="time"
                name="instalation_end_time"
                onChange={handle_report_inputs}
                value={!!add_report.instalation_end_time ? add_report.instalation_end_time.value : undefined}
                className={`${add_report.instalation_end_time?.valid ? "" : "validate-form-element"}`}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="instalation_end_time"
              />
              {(add_report.instalation_end_time === undefined || add_report.instalation_end_time?.valid === false) && (
                <ReactTooltip id="instalation_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-2">{t("pressure")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="text"
                name="pressure_under_work_pressure"
                onChange={handle_report_inputs}
                value={!!add_report.pressure_under_work_pressure ? add_report.pressure_under_work_pressure.value : undefined}
                className={`${add_report.pressure_under_work_pressure?.valid ? "" : "validate-form-element"}`}
                pattern="^\d+$"
                data-tip
                data-for="pressure_under_work_pressure"
              />
              {(add_report.pressure_under_work_pressure === undefined || add_report.pressure_under_work_pressure?.valid === false) && (
                <ReactTooltip id="pressure_under_work_pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("minimun_of_125_hPa")} </div>
            <button className="col-1">
              <Icon icon="bi:qr-code-scan" />
            </button>
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-2">{t("pressure_drop")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto", height: "28px" }}>
              <input
                type="text"
                name="pressure_drop_under_work_pressure"
                onChange={handle_report_inputs}
                value={!!add_report.pressure_drop_under_work_pressure ? add_report.pressure_drop_under_work_pressure.value : undefined}
                className={`${add_report.pressure_drop_under_work_pressure?.valid ? "" : "validate-form-element"}`}
                pattern="^\d+$"
                data-tip
                data-for="pressure_drop_under_work_pressure"
              />
              {(add_report.pressure_drop_under_work_pressure === undefined || add_report.pressure_drop_under_work_pressure?.valid === false) && (
                <ReactTooltip id="pressure_drop_under_work_pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-6">{t("under_word_pressure_drop")}</div>
          </div>
          <div className="row">
            <div className="col-2">{t("conclusion")}</div>
            <div className="col-3">
              <select
                name="under_work_pressure_conclussion"
                className={`styled-select ${add_report.under_work_pressure_conclussion?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={
                  add_report.under_work_pressure_conclussion && add_report.under_work_pressure_conclussion.value !== ""
                    ? add_report.under_work_pressure_conclussion.value
                    : -1
                }
                pattern="^[0|1]$"
                data-tip
                data-for="under_work_pressure_conclussion"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="1">{t("it_is")}</option>
                <option value="0">{t("it_is_not")}</option>
              </select>
              {(add_report.under_work_pressure_conclussion === undefined || add_report.under_work_pressure_conclussion?.valid === false) && (
                <ReactTooltip id="under_work_pressure_conclussion" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-5">{t("gastight")}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                // console.log(!!add_report.foreign_report_id,Number.isInteger(parseInt(add_report.foreign_report_id)));
                let valid = true;
                Object.keys(add_report).map((index) => {
                  if (add_report[index]["valid"] === false) valid = false;
                  return true;
                });
                console.log(add_report);
                if (valid) {
                  if (!!add_report.foreign_report_id?.value && Number.isInteger(parseInt(add_report.foreign_report_id?.value))) {
                    editReport();
                  } else {
                    addReport();
                  }
                } else {
                  toast.error(t("invalid_form"));
                }
              }}
            >
              <Icon icon="line-md:confirm" />
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={wyzard_report_gas_density_existent} size="lg" className="backgroundcolor">
        <ModalHeader
          toggle={() => opencloseModals(set_wyzard_report_gas_density_existent, wyzard_report_gas_density_existent)}
          cssModule={{ "modal-title": "larger-letter" }}
        >
          {t("report_gas_density_existent")}
        </ModalHeader>
        <ModalBody>
          <h5>{t("gas_supply")}</h5>
          <hr />
          <div className="row">
            <p className="fw-bold col-6">{t("gas_tightness_test_using_air")}</p>
            <p className="fw-bold col-6">{t("close_all_valves_beforehand")}</p>
          </div>
          <div className="row">
            <div className="col-2">{t("start_time")}</div>
            <div className={`col-4 input-group custom-input`} style={{ width: "auto" }}>
              <input
                type="time"
                name="supply_start_time"
                onChange={handle_report_inputs}
                className={`${add_report.supply_start_time?.valid ? "" : "validate-form-element"}`}
                value={!!add_report.supply_start_time ? add_report.supply_start_time.value : undefined}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="supply_start_time"
              />
              {(add_report.supply_start_time === undefined || add_report.supply_start_time?.valid === false) && (
                <ReactTooltip id="supply_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-2">{t("end_time")}</div>
            <div className={`col-4 input-group custom-input`} style={{ width: "auto" }}>
              <input
                type="time"
                name="supply_end_time"
                className={`${add_report.supply_end_time?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.supply_end_time ? add_report.supply_end_time.value : undefined}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="supply_end_time"
              />
              {(add_report.supply_end_time === undefined || add_report.supply_end_time?.valid === false) && (
                <ReactTooltip id="supply_end_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-2">{t("pressure")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="text"
                name="pressure"
                className={`${add_report.pressure?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.pressure ? add_report.pressure.value : undefined}
                pattern="^\d+$"
                data-tip
                data-for="pressure"
              />
              {(add_report.pressure === undefined || add_report.pressure?.valid === false) && (
                <ReactTooltip id="pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("minimun_of_125_hPa")}</div>
            <button className="col-1">
              <Icon icon="bi:qr-code-scan" />
            </button>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-2">{t("pressure_drop")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto", height: "28px" }}>
              <input
                type="text"
                name="pressure_drop"
                className={`${add_report.pressure_drop?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={!!add_report.pressure_drop ? add_report.pressure_drop.value : undefined}
                pattern="^\d+$"
                data-tip
                data-for="pressure_drop"
              />
              {(add_report.pressure_drop === undefined || add_report.pressure_drop?.valid === false) && (
                <ReactTooltip id="pressure_drop" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("maximum_pressure_drop_of_1hPa")}</div>
          </div>
          <div className="row">
            <div className="col-2">{t("conclusion")}</div>
            <div className="col-3">
              <select
                name="conclussion"
                className={`styled-select ${add_report.conclussion?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={add_report.conclussion && add_report.conclussion.value !== "" ? add_report.conclussion.value : -1}
                pattern="^[0|1]$"
                data-tip
                data-for="conclussion"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="1">{t("it_is")}</option>
                <option value="0">{t("it_is_not")}</option>
              </select>
              {(add_report.conclussion === undefined || add_report.conclussion?.valid === false) && (
                <ReactTooltip id="conclussion" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-5">{t("gastight")}</div>
          </div>
          <br />
          <h5>{t("gas_installation")}</h5>
          <hr />
          <div className="row">
            <div className="col-2">{t("start_time")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="time"
                name="instalation_start_time"
                onChange={handle_report_inputs}
                value={!!add_report.instalation_start_time ? add_report.instalation_start_time.value : undefined}
                className={`${add_report.instalation_start_time?.valid ? "" : "validate-form-element"}`}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="instalation_start_time"
              />
              {(add_report.instalation_start_time === undefined || add_report.instalation_start_time?.valid === false) && (
                <ReactTooltip id="instalation_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-2">{t("end_time")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="time"
                name="instalation_end_time"
                onChange={handle_report_inputs}
                value={!!add_report.instalation_end_time ? add_report.instalation_end_time.value : undefined}
                className={`${add_report.instalation_end_time?.valid ? "" : "validate-form-element"}`}
                pattern="^\d{2}:\d{2}$"
                data-tip
                data-for="instalation_end_time"
              />
              {(add_report.instalation_end_time === undefined || add_report.instalation_end_time?.valid === false) && (
                <ReactTooltip id="instalation_start_time" place="top" effect="solid">
                  {t("^\\d{2}\\d{2}$")}
                </ReactTooltip>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-2">{t("pressure")}</div>
            <div className="col-5 input-group custom-input" style={{ width: "auto" }}>
              <input
                type="text"
                name="pressure_under_work_pressure"
                onChange={handle_report_inputs}
                value={!!add_report.pressure_under_work_pressure ? add_report.pressure_under_work_pressure.value : undefined}
                className={`${add_report.pressure_under_work_pressure?.valid ? "" : "validate-form-element"}`}
                pattern="^\d+$"
                data-tip
                data-for="pressure_under_work_pressure"
              />
              {(add_report.pressure_under_work_pressure === undefined || add_report.pressure_under_work_pressure?.valid === false) && (
                <ReactTooltip id="pressure_under_work_pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-5">{t("minimun_of_125_hPa")} </div>
            <button className="col-1">
              <Icon icon="bi:qr-code-scan" />
            </button>
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-2">{t("pressure_drop")}</div>
            <div className="col-4 input-group custom-input" style={{ width: "auto", height: "28px" }}>
              <input
                type="text"
                name="pressure_drop_under_work_pressure"
                onChange={handle_report_inputs}
                value={!!add_report.pressure_drop_under_work_pressure ? add_report.pressure_drop_under_work_pressure.value : undefined}
                className={`${add_report.pressure_drop_under_work_pressure?.valid ? "" : "validate-form-element"}`}
                pattern="^\d+$"
                data-tip
                data-for="pressure_drop_under_work_pressure"
              />
              {(add_report.pressure_drop_under_work_pressure === undefined || add_report.pressure_drop_under_work_pressure?.valid === false) && (
                <ReactTooltip id="pressure_drop_under_work_pressure" place="top" effect="solid">
                  {t("^\\d+$")}
                </ReactTooltip>
              )}
              <div className="input-group-append">
                <span>hPa</span>
              </div>
            </div>
            <div className="col-6">{t("under_word_pressure_drop")}</div>
          </div>
          <div className="row">
            <div className="col-2">{t("conclusion")}</div>
            <div className="col-3">
              <select
                name="under_work_pressure_conclussion"
                className={`styled-select ${add_report.under_work_pressure_conclussion?.valid ? "" : "validate-form-element"}`}
                onChange={handle_report_inputs}
                value={
                  add_report.under_work_pressure_conclussion && add_report.under_work_pressure_conclussion.value !== ""
                    ? add_report.under_work_pressure_conclussion.value
                    : -1
                }
                pattern="^[0|1]$"
                data-tip
                data-for="under_work_pressure_conclussion"
              >
                <option value="-1" disabled hidden>
                  {t("select_an_option")}
                </option>
                <option value="1">{t("it_is")}</option>
                <option value="0">{t("it_is_not")}</option>
              </select>
              {(add_report.under_work_pressure_conclussion === undefined || add_report.under_work_pressure_conclussion?.valid === false) && (
                <ReactTooltip id="under_work_pressure_conclussion" place="top" effect="solid">
                  {t("select_an_option")}
                </ReactTooltip>
              )}
            </div>
            <div className="col-5">{t("gastight")}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              // console.log(!!add_report.foreign_report_id,Number.isInteger(parseInt(add_report.foreign_report_id)));
              let valid = true;
              Object.keys(add_report).map((index) => {
                if (add_report[index]["valid"] === false) valid = false;
                return true;
              });
              console.log(add_report);
              if (valid) {
                if (!!add_report.foreign_report_id?.value && Number.isInteger(parseInt(add_report.foreign_report_id?.value))) {
                  editReport();
                } else {
                  addReport();
                }
              } else {
                toast.error(t("invalid_form"));
              }
            }}
          >
            <Icon icon="line-md:confirm" />
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={wyzard_radiator_and_contactor_adjustment} size="lg" className="backgroundcolor">
        <ModalHeader
          toggle={() => opencloseModals(set_wyzard_radiator_and_contactor_adjustment, wyzard_radiator_and_contactor_adjustment)}
          cssModule={{ "modal-title": "larger-letter" }}
        >
          {t("radiator_and_contactor_adjustment")}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive table-responsive-data2">
            <table className="table table-data2">
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>{t("location")}</th>
                  <th style={{ width: "25%" }}>{t("type")}</th>
                  <th style={{ width: "5%" }}>{t("length")}</th>
                  <th style={{ width: "5%" }}>{t("height")}</th>
                  <th style={{ width: "5%" }}>{t("capacity")}</th>
                  <th style={{ width: "10%" }}>{t("adjustment_mode")}</th>
                  <th style={{ width: "10%" }}>
                    <div className="table-data-feature">
                      <button
                        className="item orangeBack"
                        data-tip
                        data-for={`AddTip`}
                        onClick={() => {
                          set_add_report((prevState) => {
                            let tmp = Object.assign({}, prevState);
                            Object.keys(tmp).map((tmpindex) => {
                              if (tmp[tmpindex]["value"].push) {
                                console.log(tmp[tmpindex]["value"], tmpindex);
                                tmp[tmpindex]["value"].push({ value: "", valid: "false" });
                              }
                              return true;
                            });
                            return tmp;
                          });
                        }}
                      >
                        <Icon icon="zmdi:plus" />
                      </button>
                      <ReactTooltip id={`AddTip`} place="top" effect="solid">
                        {t("add")}
                      </ReactTooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {add_report["location[]"]?.value.map &&
                  add_report["location[]"].value.map((data, index) => {
                    return (
                      <React.Fragment key={`${index}fragment`}>
                        <tr className="tr-shadow modal-data-table" key={index}>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["location[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`location[]`}
                              id={`location[]${index}`}
                              onChange={(e) => handleReportMultiInputs(index, e)}
                              value={add_report["location[]"].value[index].value}
                              pattern="^[\w\s]+$"
                              data-tip
                              data-for={`location${index}`}
                            />
                            {add_report["location[]"].value[index].valid === false && (
                              <ReactTooltip id={`location${index}`} place="top" effect="solid">
                                {t("^[\\w\\s]+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["type[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`type[]`}
                              id={`type[]${index}`}
                              onChange={(e) => handleReportMultiInputs(index, e)}
                              value={add_report["type[]"].value[index].value}
                              pattern="^[\w\s]+$"
                              data-tip
                              data-for={`type${index}`}
                            />
                            {add_report["type[]"].value[index].valid === false && (
                              <ReactTooltip id={`type${index}`} place="top" effect="solid">
                                {t("^[\\w\\s]+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["length[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`length[]`}
                              id={`length[]${index}`}
                              onChange={(e) => handleReportMultiInputs(index, e)}
                              value={add_report["length[]"].value[index].value}
                              pattern="^\d+$"
                              data-tip
                              data-for={`length${index}`}
                            />
                            {add_report["length[]"].value[index].valid === false && (
                              <ReactTooltip id={`length${index}`} place="top" effect="solid">
                                {t("^\\d+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["height[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`height[]`}
                              id={`height[]${index}`}
                              onChange={(e) => handleReportMultiInputs(index, e)}
                              value={add_report["height[]"].value[index].value}
                              pattern="^\d+$"
                              data-tip
                              data-for={`height${index}`}
                            />
                            {add_report["height[]"].value[index].valid === false && (
                              <ReactTooltip id={`height${index}`} place="top" effect="solid">
                                {t("^\\d+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["capacity[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`capacity[]`}
                              id={`capacity[]${index}`}
                              onChange={(e) => {
                                handleReportMultiInputs(index, e);
                                set_add_report((prevState) => {
                                  let tmp = Object.assign({}, prevState);
                                  let sum = 0;
                                  Object.keys(tmp["capacity[]"].value).map((i) => {
                                    // console.log(tmp.capacity.value[i].value, i);
                                    if (tmp["capacity[]"].value[i]?.valid) {
                                      sum = parseInt(sum) + parseInt(tmp["capacity[]"].value[i].value);
                                    }
                                    if (sum > 0) {
                                      tmp.total_capacity.valid = true;
                                      tmp.total_capacity.value = sum;
                                    }
                                    return true;
                                  });
                                  // console.log(tmp);
                                  return tmp;
                                });
                              }}
                              value={add_report["capacity[]"].value[index].value}
                              pattern="^[.,\d]+$"
                              data-tip
                              data-for={`capacity${index}`}
                            />
                            {add_report["capacity[]"].value[index].valid === false && (
                              <ReactTooltip id={`capacity${index}`} place="top" effect="solid">
                                {t("^\\d+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control lightgreyBack ${add_report["adjustment_mode[]"].value[index].valid ? "" : "validate-form-element"}`}
                              type="text"
                              name={`adjustment_mode[]`}
                              id={`adjustment_mode[]${index}`}
                              onChange={(e) => handleReportMultiInputs(index, e)}
                              value={add_report["adjustment_mode[]"].value[index].value}
                              pattern="^\w+$"
                              data-tip
                              data-for={`adjustment_mode${index}`}
                            />
                            {add_report["adjustment_mode[]"].value[index].valid === false && (
                              <ReactTooltip id={`adjustment_mode${index}`} place="top" effect="solid">
                                {t("^\\w+$")}
                              </ReactTooltip>
                            )}
                          </td>
                          <td style={{ paddingRight: "1.5%" }}>
                            <div className="table-data-feature">
                              <button
                                className="item orangeBack"
                                data-tip
                                data-for={`DeleteTip${index}`}
                                onClick={() => {
                                  set_add_report((prevState) => {
                                    let tmp = Object.assign({}, prevState);
                                    Object.keys(tmp).map((tmpindex) => {
                                      //tmp[tmpindex]["value"].push
                                      if (tmp[tmpindex]["value"].map && tmp[tmpindex]["value"][index]) {
                                        console.log(tmp[tmpindex]["value"], tmpindex, index);
                                        tmp[tmpindex]["value"].splice(index, 1);
                                      }
                                      return true;
                                    });
                                    return tmp;
                                  });
                                }}
                              >
                                <Icon icon="line-md:remove" />
                              </button>
                              <ReactTooltip id={`DeleteTip${index}`} place="top" effect="solid">
                                {t("delete")}
                              </ReactTooltip>
                            </div>
                          </td>
                        </tr>
                        <tr className="spacer" key={`${index}/asdf`}></tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
            <div className="row d-flex align-items-center">
              <div className="col-4">{t("supply_temperature")}</div>
              <div className="col-6 input-group custom-input" style={{ width: "auto", height: "28px" }}>
                <input
                  type="text"
                  name="supply_temperature"
                  className={`${add_report.supply_temperature?.valid ? "" : "validate-form-element"}`}
                  onChange={handle_report_inputs}
                  value={!!add_report.supply_temperature ? add_report.supply_temperature.value : undefined}
                  pattern="^\d+$"
                  data-tip
                  data-for="supply_temperature"
                />
                {(add_report.supply_temperature === undefined || add_report.supply_temperature?.valid === false) && (
                  <ReactTooltip id="supply_temperature" place="top" effect="solid">
                    {t("^\\d+$")}
                  </ReactTooltip>
                )}
                <div className="input-group-append">
                  <span>
                    <Icon icon="mdi:temperature-celsius" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-4">{t("return_temperature")}</div>
              <div className="col-6 input-group custom-input" style={{ width: "auto", height: "28px" }}>
                <input
                  type="text"
                  name="return_temperature"
                  className={`${add_report.return_temperature?.valid ? "" : "validate-form-element"}`}
                  onChange={handle_report_inputs}
                  value={!!add_report.return_temperature ? add_report.return_temperature.value : undefined}
                  pattern="^\d+$"
                  data-tip
                  data-for="return_temperature"
                />
                {(add_report.return_temperature === undefined || add_report.return_temperature?.valid === false) && (
                  <ReactTooltip id="return_temperature" place="top" effect="solid">
                    {t("^\\d+$")}
                  </ReactTooltip>
                )}
                <div className="input-group-append">
                  <span>
                    <Icon icon="mdi:temperature-celsius" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-4">{t("pump_min_capacity")}</div>
              <div className="col-6 input-group custom-input" style={{ width: "auto", height: "28px" }}>
                <input
                  type="text"
                  name="pump_min_capacity"
                  className={`${add_report.pump_min_capacity?.valid ? "" : "validate-form-element"}`}
                  onChange={handle_report_inputs}
                  value={!!add_report.pump_min_capacity ? add_report.pump_min_capacity.value : undefined}
                  pattern="^\d+$"
                  data-tip
                  data-for="pump_min_capacity"
                />
                {(add_report.pump_min_capacity === undefined || add_report.pump_min_capacity?.valid === false) && (
                  <ReactTooltip id="pump_min_capacity" place="top" effect="solid">
                    {t("^\\d+$")}
                  </ReactTooltip>
                )}
                <div className="input-group-append">
                  <span> %</span>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-4">{t("pump_max_capacity")}</div>
              <div className="col-6 input-group custom-input" style={{ width: "auto", height: "28px" }}>
                <input
                  type="text"
                  name="pump_max_capacity"
                  className={`${add_report.pump_max_capacity?.valid ? "" : "validate-form-element"}`}
                  onChange={handle_report_inputs}
                  value={!!add_report.pump_max_capacity ? add_report.pump_max_capacity.value : undefined}
                  pattern="^\d+$"
                  data-tip
                  data-for="pump_max_capacity"
                />
                {(add_report.pump_max_capacity === undefined || add_report.pump_max_capacity?.valid === false) && (
                  <ReactTooltip id="pump_max_capacity" place="top" effect="solid">
                    {t("^\\d+$")}
                  </ReactTooltip>
                )}
                <div className="input-group-append">
                  <span> %</span>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-4">{t("total_capacity")}</div>
              <div className="col-6 input-group custom-input" style={{ width: "auto", height: "28px" }}>
                <input
                  type="text"
                  name="total_capacity"
                  disabled
                  className={`${add_report.total_capacity?.valid ? "" : "validate-form-element"}`}
                  onChange={handle_report_inputs}
                  value={!!add_report.total_capacity ? add_report.total_capacity.value : undefined}
                  pattern="^\d+$"
                  data-tip
                  data-for="total_capacity"
                />
                {(add_report.total_capacity === undefined || add_report.total_capacity?.valid === false) && (
                  <ReactTooltip id="total_capacity" place="top" effect="solid">
                    {t("^\\d+$")}
                  </ReactTooltip>
                )}
                <div className="input-group-append">
                  <span>kW</span>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              // console.log(!!add_report.foreign_report_id,Number.isInteger(parseInt(add_report.foreign_report_id)));
              let valid = true;
              Object.keys(add_report).map((index) => {
                if (add_report[index]["valid"] === false) valid = false;
                return true;
              });
              console.log(add_report);
              if (valid) {
                if (!!add_report.foreign_report_id?.value && Number.isInteger(parseInt(add_report.foreign_report_id?.value))) {
                  console.log(add_report);
                  editReport();
                } else {
                  addReport();
                  console.log(add_report);
                }
              } else {
                toast.error(t("invalid_form"));
              }
            }}
          >
            <Icon icon="line-md:confirm" />
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ReportCRUD;
