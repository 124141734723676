import React, { useContext } from "react";
import "./footer.css";
import { Icon, InlineIcon } from "@iconify/react";
import Theme, { ThemeContext } from "../Theme";

function SwitchTheme() {
    const { toggle } = useContext(ThemeContext);
    return (
      <button onClick={toggle}>
        <InlineIcon className="float-end" icon="carbon:color-switch" />
      </button>
    )
  }

function Footer() {

  


  return (
    <div className="text-center mt-auto footer">
      <div className="container">
        <div className="row">
          <div className="col-12 text-white">
            <p>Copyright © Scriptlab. All rights reserved.
              <SwitchTheme/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
