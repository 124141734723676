import React from "react";
import ClientCRUD from "./clientCRUD/ClientCRUD";

function Client() {
  return (
    <>
      <ClientCRUD />
    </>
  );
}

export default Client;
