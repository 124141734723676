import React, { useEffect, useState } from "react";
import { Icon, InlineIcon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import validator from "validator";

import "./theme.css";
import "./Pagination.css";
import { data_access } from "../../data-access";
import { Margin } from "../../custom-components/checkbox";

function ClientCRUD() {
  const userPermissions = JSON.parse(Cookies.get("userData"))["client"];
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [force_page, set_force_page] = useState(-1);
  const [filter, setFilter] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemOffset, setItemOffset] = useState(0);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [subject, set_subject] = useState("");
  const [body, set_body] = useState("");
  let valid = true;
  const [selected_client, set_selected_client] = useState({
    id: "",
    company_name: { value: "", valid: false },
    first_name: { value: "", valid: false },
    lastname: { value: "", valid: false },
    phone: { value: "", valid: false },
    mobile: { value: "", valid: false },
    email: { value: "", valid: false },
    vat_number: { value: "", valid: false },
    website: { value: "", valid: false },
    city: { value: "", valid: false },
    adress: { value: "", valid: false },
    house_number: { value: "", valid: false },
    postcode: { value: "", valid: false },
  });
  const pagination_options = [
    { value: "2", label: "2" },
    { value: "4", label: "4" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
  ];
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "1px solid #ffffff",
      background: "#df8702",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      border: "none",
      background: "#df8702",
    }),
    singleValue: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
    }),
    menuList: (styles) => ({
      ...styles,
      cursor: "pointer",
      color: "#ffffff",
      background: "#3d3d3d",
    }),
  };
  const select_client = (client, action) => {
    let temp = JSON.parse(JSON.stringify(client));
    Object.keys(temp).map((index) => {
      set_selected_client((prevState) => ({
        ...prevState,
        [index]: { value: temp[index], valid: true },
      }));
      return true;
    });
    if (action === "edit") {
      opencloseModalEdit();
    } else if (action === "delete") {
      opencloseModalDelete();
    } else if (action === "email") {
      opencloseModalEmail();
    }
  };

  const change_pagination = (v, e) => {
    setItemsPerPage(v.value);
    setItemOffset(0);
    set_force_page(0);
  };
  const handleEmailBody = (e) => {
    set_body(e.target.value);
  };
  const handleEmailSubject = (e) => {
    set_subject(e.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // console.log(e);
    if (e.target.attributes["pattern"]) {
      const pattern = e.target.attributes["pattern"].value;
      let re = new RegExp(pattern);
      var valid = re.test(value);
      if (valid) {
        e.target.classList.remove("validate-form-element");
      } else {
        e.target.classList.add("validate-form-element");
      }
      // console.log(pattern, valid, value);
    }
    set_selected_client((prevState) => ({
      ...prevState,
      [name]: { value: value, valid: valid },
    }));
  };
  const opencloseModalDelete = () => {
    setModalDelete(!modalDelete);
  };
  const opencloseModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  const opencloseModalAdd = () => {
    setModalAdd(!modalAdd);
  };
  const opencloseModalEmail = () => {
    setModalEmail(!modalEmail);
  };
  const doGetClientList = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("email", userData.email);
    f.append("token", userData.token);
    f.append("serie", userData.serie);
    f.append("METHOD", "GET");
    try {
      await axios.post(`${data_access.API_URL}client.php`, f).then((response) => {
        setData(response.data);
      });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(t("insufficient_permissions"));
      } else {
        console.error(e);
      }
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * parseInt(itemsPerPage)) % data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (!!Cookies.get("userData")) {
      doGetClientList();
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // Fetch items from another resources.
      const endOffset = itemOffset + parseInt(itemsPerPage);
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      // console.log(data);

      let filteredData = data.filter((o) => Object.keys(o).some((k) => o[k].toLowerCase().includes(filter.toLowerCase())));
      setCurrentItems(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
      set_force_page(endOffset / parseInt(itemsPerPage) - 1);
    }
  }, [itemOffset, itemsPerPage, data, filter]);

  const Filter_data = (e) => {
    setFilter(e.target.value);
    setItemOffset(0);
    set_force_page(0);
  };

  const validfunction = (typeValid, Input) => {
    let returnvalue = false;
    switch (typeValid) {
      case "isEmail":
        if (validator.isEmail(Input) === false) {
          returnvalue = false;
          toast.error(t("EmailWrong"));
        } else {
          returnvalue = true;
        }
        break;
      case "isURL":
        if (validator.isURL(Input) === false) {
          returnvalue = false;
          toast.error(t("URLWrong"));
        } else {
          returnvalue = true;
        }
        break;
      case "isNumber":
        if (validator.isNumeric(Input) === false) {
          returnvalue = false;
          toast.error(t("NumberWrong"));
        } else {
          returnvalue = true;
        }
        break;

      default:
        break;
    }
    console.log(returnvalue);
    valid = valid && returnvalue;
  };

  const doAddClient = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    validfunction("isURL", selected_client.website.value);
    validfunction("isEmail", selected_client.email.value);
    console.log(valid && valid);
    if (valid === true) {
      var f = new FormData();
      f.append("METHOD", "POST");
      f.append("authtoken", userData.token);
      f.append("authserie", userData.serie);
      f.append("authemail", userData.email);
      f.append("company_name", selected_client.company_name.value);
      f.append("first_name", selected_client.first_name.value);
      f.append("lastname", selected_client.lastname.value);
      f.append("phone", selected_client.phone.value);
      f.append("mobile", selected_client.mobile.value);
      f.append("email", selected_client.email.value);
      f.append("vat_number", selected_client.vat_number.value);
      f.append("website", selected_client.website.value);
      f.append("city", selected_client.city.value);
      f.append("adress", selected_client.adress.value);
      f.append("house_number", selected_client.house_number.value);
      f.append("postcode", selected_client.postcode.value);
      await axios
        .post(`${data_access.API_URL}client.php`, f)
        .then((response) => {
          setData(data.concat(response.data));
          opencloseModalAdd();
          toast.success(t("client_add_success"));
        })
        .catch((e) => {
          if (e.response.status === 403) {
            opencloseModalAdd();
            toast.error(t("insufficient_permissions"));
          } else {
            opencloseModalAdd();
            console.error(e);
          }
        });
    }
  };

  const doEdit = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("METHOD", "PUT");
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("authemail", userData.email);
    f.append("id", selected_client.id);
    f.append("company_name", selected_client.company_name.value);
    f.append("first_name", selected_client.first_name.value);
    f.append("lastname", selected_client.lastname.value);
    f.append("phone", selected_client.phone.value);
    f.append("mobile", selected_client.mobile.value);
    f.append("email", selected_client.email.value);
    f.append("vat_number", selected_client.vat_number.value);
    f.append("website", selected_client.website.value);
    f.append("city", selected_client.city.value);
    f.append("adress", selected_client.adress.value);
    f.append("house_number", selected_client.house_number.value);
    f.append("postcode", selected_client.postcode.value);
    await axios
      .post(`${data_access.API_URL}client.php`, f)
      .then((response) => {
        let newData = data;
        newData.forEach((client) => {
          if (client.id === selected_client.id) {
            client.company_name = selected_client.company_name.value;
            client.first_name = selected_client.first_name.value;
            client.lastname = selected_client.lastname.value;
            client.phone = selected_client.phone.value;
            client.mobile = selected_client.mobile.value;
            client.email = selected_client.email.value;
            client.vat_number = selected_client.vat_number.value;
            client.website = selected_client.website.value;
            client.city = selected_client.city.value;
            client.adress = selected_client.adress.value;
            client.house_number = selected_client.house_number.value;
            client.postcode = selected_client.postcode.value;
          }
        });
        setData(newData);
        opencloseModalEdit();
        toast.success(t("client_edit_success"));
      })
      .catch((e) => {
        if (e.response.status === 403) {
          opencloseModalAdd();
          toast.error(t("insufficient_permissions"));
        } else {
          opencloseModalAdd();
          console.error(e);
        }
      });
  };

  const doDelete = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("METHOD", "DELETE");
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("authemail", userData.email);
    f.append("id", selected_client.id);

    await axios
      .post(`${data_access.API_URL}client.php`, f)
      .then((response) => {
        setData(data.filter((client) => client.id !== response.data.id));
        opencloseModalDelete();
        toast.success(t("client_delete_success"));
      })
      .catch((e) => {
        if (e.response.status === 403) {
          opencloseModalAdd();
          toast.error(t("insufficient_permissions"));
        } else {
          opencloseModalAdd();
          console.error(e);
        }
      });
  };
  const sendEmail = async () => {
    const userData = JSON.parse(Cookies.get("userData"));
    var f = new FormData();
    f.append("METHOD", "EMAIL");
    f.append("authtoken", userData.token);
    f.append("authserie", userData.serie);
    f.append("authemail", userData.email);
    f.append("to", selected_client.email.value);
    f.append("subject", subject);
    f.append("body", body);
    await axios.post(`${data_access.API_URL}`, f).then((response) => {
      opencloseModalEmail();
      toast.success(t("email_sent"));
    });
  };
  return (
    <Margin top={20 + "px"}>
      {/* <div className="row">
            <div className="col-md-12"> 
     <!-- DATA TABLE --> <h3 className="title-5 m-b-35">data table</h3>*/}

      {userPermissions[3] === "1" && (
        <div className="table-data__tool">
          <div className="table-data__tool-left col">
            {/* <div className="rs-select2--light rs-select2--md">
            <select className="js-select2" name="property">
              <option selected="selected">All Properties</option>
              <option value="">Option 1</option>
              <option value="">Option 2</option>
            </select>
            <div className="dropDownSelect2"></div>
          </div> */}
            {/* <div className="rs-select2--light rs-select2--sm">
            <select className="js-select2" name="time">
              <option selected="selected">Today</option>
              <option value="">3 Days</option>
              <option value="">1 Week</option>
            </select>
            <div className="dropDownSelect2"></div>
          </div> */}
            <div className="input-group show-entries">
              <div className="input-group-prepend">
                <span className="before" type="button">
                  <InlineIcon icon="zmdi:filter-list" />
                </span>
              </div>
              <Select
                options={pagination_options}
                onChange={change_pagination}
                defaultValue={pagination_options[1]}
                name="pagination"
                styles={customStyles}
                className="orangeBack"
              />
              <div className="input-group-append">
                <span className="after" type="button">
                  entries
                </span>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <input
                type="text"
                className="form-control lightgreyBack"
                placeholder="Filter"
                aria-label="Filter"
                aria-describedby="basic-addon1"
                onChange={Filter_data}
              />
              <div className="input-group-append">
                <span className="input-group-text orangeBack" id="basic-addon1">
                  <InlineIcon style={{ cursor: "pointer" }} className="orangeBack" icon="ic:outline-manage-search" height="24" />
                </span>
              </div>
            </div>
          </div>

          {userPermissions[2] === "1" && (
            <div className="table-data__tool-right col">
              <button className="au-btn au-btn-icon au-btn--small au-btn-add float-end orangeBack" onClick={() => opencloseModalAdd()}>
                <InlineIcon icon="zmdi:plus" className="bi bi-align-center" />
                {t("add")}
              </button>
            </div>
          )}
        </div>
      )}
      {userPermissions[3] === "1" && (
        <div className="table-responsive table-responsive-data2">
          <table className="table table-data2">
            <thead>
              <tr>
                <th className="orangeFront">{t("company_name")}</th>
                <th>{t("adress")}</th>
                <th>{t("house_number")}</th>
                <th>{t("city")}</th>
                <th>{t("postcode")}</th>
                <th>{t("mobile")}</th>
                <th>{t("email")}</th>
                <th>{t("website")}</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((client) => {
                return (
                  <React.Fragment key={`${client.id}fragment`}>
                    <tr className="tr-shadow" key={client.id}>
                      <td>
                        <span className="orangeFront">{client.company_name}</span>
                      </td>
                      <td>
                        {/* <span className="block-email">lori@example.com</span> */}
                        {client.adress}
                      </td>
                      <td>{client.house_number}</td>
                      <td>{client.city}</td>
                      <td>{client.postcode}</td>
                      <td>{client.mobile}</td>
                      <td>{client.email}</td>
                      <td className="desc">
                        <a href={client.website} target="_blank">
                          {client.website}
                        </a>
                      </td>
                      <td>
                        <div className="table-data-feature">
                          {/* <button
                                className="item"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Send"
                              >
                                <Icon icon="zmdi:mail-send" />
                              </button> */}
                          {userPermissions[1] === "1" && (
                            <>
                              <button className="item orangeBack" data-tip data-for={`EditTip${client.id}`} onClick={() => select_client(client, "edit")}>
                                <Icon icon="line-md:pencil" />
                              </button>
                              <ReactTooltip id={`EditTip${client.id}`} place="top" effect="solid">
                                {t("edit")}
                              </ReactTooltip>
                            </>
                          )}
                          {userPermissions[0] === "1" && (
                            <>
                              <button className="item orangeBack" data-tip data-for={`DeleteTip${client.id}`} onClick={() => select_client(client, "delete")}>
                                <Icon icon="line-md:remove" />
                              </button>
                              <ReactTooltip id={`DeleteTip${client.id}`} place="top" effect="solid">
                                {t("delete")}
                              </ReactTooltip>
                            </>
                          )}
                          {userPermissions[3] === "1" && (
                            <>
                              <button className="item orangeBack" data-tip data-for={`EmailTip${client.id}`} onClick={() => select_client(client, "email")}>
                                <Icon icon="line-md:email" />
                              </button>
                              <ReactTooltip id={`EmailTip${client.id}`} place="top" effect="solid">
                                {t("email")}
                              </ReactTooltip>
                            </>
                          )}
                          {/* <button
                              className="item"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="More"
                            >
                              <Icon icon="zmdi:more" />
                            </button> */}
                        </div>
                      </td>
                    </tr>
                    <tr className="spacer" key={`${client.id}/asdf`}></tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
            forcePage={force_page}
          />
        </div>
      )}
      {/* <!-- END DATA TABLE -->  </div>
    </div>*/}
      {userPermissions[2] === "1" && (
        <Modal isOpen={modalAdd}>
          <ModalHeader>{t("add_client")}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="company_name">{t("company_name")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="company_name"
                onChange={handleChange}
                id="company_name"
                pattern="^[-.a-zA-Z0-9\s]+$"
                data-tip
                data-for="company_name"
              />
              {selected_client.company_name.valid === false && (
                <ReactTooltip id="company_name" place="top" effect="solid">
                  {t("^[-.a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="first_name"
                onChange={handleChange}
                id="first_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="first_name"
              />
              {selected_client.first_name.valid === false && (
                <ReactTooltip id="first_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="lastname">{t("lastname")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="lastname"
                onChange={handleChange}
                id="lastname"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="lastname"
              />
              {selected_client.lastname.valid === false && (
                <ReactTooltip id="lastname" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t("phone")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="phone"
                onChange={handleChange}
                id="phone"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="phone"
              />
              {selected_client.phone.valid === false && (
                <ReactTooltip id="phone" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="mobile">{t("mobile")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="mobile"
                onChange={handleChange}
                id="mobile"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="mobile"
              />
              {selected_client.mobile.valid === false && (
                <ReactTooltip id="mobile" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{t("email")}(*)</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="email"
                onChange={handleChange}
                id="email"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                data-tip
                data-for="email"
              />
              {selected_client.email.valid === false && (
                <ReactTooltip id="email" place="top" effect="solid">
                  {t("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="vat_number">{t("vat_number")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="vat_number"
                onChange={handleChange}
                id="vat_number"
                pattern="^[\w\s]{5,}$"
                data-tip
                data-for="vat_number"
              />
              {selected_client.vat_number.valid === false && (
                <ReactTooltip id="vat_number" place="top" effect="solid">
                  {t("^[\\w\\s]{5,}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="website">{t("website")}(*)</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="website"
                onChange={handleChange}
                id="website"
                pattern="^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}$"
                data-tip
                data-for="website"
              />
              {selected_client.website.valid === false && (
                <ReactTooltip id="website" place="top" effect="solid">
                  {t("^(https?:\\/\\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="city">{t("city")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="city"
                onChange={handleChange}
                id="city"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="city"
              />
              {selected_client.city.valid === false && (
                <ReactTooltip id="city" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="adress">{t("adress")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="adress"
                onChange={handleChange}
                id="adress"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="adress"
              />
              {selected_client.adress.valid === false && (
                <ReactTooltip id="adress" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="house_number">{t("house_number")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="house_number"
                onChange={handleChange}
                id="house_number"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="house_number"
              />
              {selected_client.house_number.valid === false && (
                <ReactTooltip id="house_number" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="postcode">{t("postcode")}</label>
              <input
                className="form-control lightgreyBack validate-form-element"
                type="text"
                name="postcode"
                onChange={handleChange}
                id="postcode"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="postcode"
              />
              {selected_client.postcode.valid === false && (
                <ReactTooltip id="postcode" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              onClick={() => {
                let valid = true;
                Object.keys(selected_client).map((index) => {
                  if (selected_client[index]["valid"] === false) valid = false;
                  return true;
                });
                if (valid) {
                  doAddClient();
                } else {
                  toast.error(t("invalid_form"));
                }
              }}
            >
              {t("add")}
            </button>
            <button className="btn btn-secondary" onClick={() => opencloseModalAdd()}>
              {t("cancel")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {userPermissions[1] === "1" && (
        <Modal isOpen={modalEdit}>
          <ModalHeader>{t("add_client")}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="company_name">{t("company_name")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="company_name"
                onChange={handleChange}
                value={selected_client && selected_client.company_name.value}
                id="company_name"
                pattern="^[-.a-zA-Z0-9\s]+$"
                data-tip
                data-for="company_name"
              />
              {selected_client.company_name.valid === false && (
                <ReactTooltip id="company_name" place="top" effect="solid">
                  {t("^[-.a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="first_name">{t("first_name")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="first_name"
                onChange={handleChange}
                value={selected_client && selected_client.first_name.value}
                id="first_name"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="first_name"
              />
              {selected_client.first_name.valid === false && (
                <ReactTooltip id="first_name" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="lastname">{t("lastname")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="lastname"
                onChange={handleChange}
                value={selected_client && selected_client.lastname.value}
                id="lastname"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="lastname"
              />
              {selected_client.lastname.valid === false && (
                <ReactTooltip id="lastname" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t("phone")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="phone"
                onChange={handleChange}
                value={selected_client && selected_client.phone.value}
                id="phone"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="phone"
              />
              {selected_client.phone.valid === false && (
                <ReactTooltip id="phone" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="mobile">{t("mobile")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="mobile"
                onChange={handleChange}
                value={selected_client && selected_client.mobile.value}
                id="mobile"
                pattern="^\+?[\d()\s]{9,20}$"
                data-tip
                data-for="mobile"
              />
              {selected_client.mobile.valid === false && (
                <ReactTooltip id="mobile" place="top" effect="solid">
                  {t("^\\+?[\\d()\\s]{9,20}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{t("email")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="email"
                onChange={handleChange}
                value={selected_client && selected_client.email.value}
                id="email"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                data-tip
                data-for="email"
              />
              {selected_client.email.valid === false && (
                <ReactTooltip id="email" place="top" effect="solid">
                  {t("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="vat_number">{t("vat_number")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="vat_number"
                onChange={handleChange}
                value={selected_client && selected_client.vat_number.value}
                id="vat_number"
                pattern="^[\w\s]{5,}$"
                data-tip
                data-for="vat_number"
              />
              {selected_client.vat_number.valid === false && (
                <ReactTooltip id="vat_number" place="top" effect="solid">
                  {t("^[\\w\\s]{5,}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="website">{t("website")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="website"
                onChange={handleChange}
                value={selected_client && selected_client.website.value}
                id="website"
                pattern="^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}$"
                data-tip
                data-for="website"
              />
              {selected_client.website.valid === false && (
                <ReactTooltip id="website" place="top" effect="solid">
                  {t("^(https?:\\/\\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="city">{t("city")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="city"
                onChange={handleChange}
                value={selected_client && selected_client.city.value}
                id="city"
                pattern="^[a-zA-Z\s]+$"
                data-tip
                data-for="city"
              />
              {selected_client.city.valid === false && (
                <ReactTooltip id="city" place="top" effect="solid">
                  {t("^[a-zA-Z\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="adress">{t("adress")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="adress"
                onChange={handleChange}
                value={selected_client && selected_client.adress.value}
                id="adress"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="adress"
              />
              {selected_client.adress.valid === false && (
                <ReactTooltip id="adress" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="house_number">{t("house_number")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="house_number"
                onChange={handleChange}
                value={selected_client && selected_client.house_number.value}
                id="house_number"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="house_number"
              />
              {selected_client.house_number.valid === false && (
                <ReactTooltip id="house_number" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="postcode">{t("postcode")}</label>
              <input
                className="form-control lightgreyBack"
                type="text"
                name="postcode"
                onChange={handleChange}
                value={selected_client && selected_client.postcode.value}
                id="postcode"
                pattern="^[a-zA-Z0-9\s]+$"
                data-tip
                data-for="postcode"
              />
              {selected_client.postcode.valid === false && (
                <ReactTooltip id="postcode" place="top" effect="solid">
                  {t("^[a-zA-Z0-9\\s]+$")}
                </ReactTooltip>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => doEdit()}>
              {t("edit")}
            </button>
            <button className="btn btn-secondary" onClick={() => opencloseModalEdit()}>
              {t("cancel")}
            </button>
          </ModalFooter>
        </Modal>
      )}
      {userPermissions[0] === "1" && (
        <Modal isOpen={modalDelete}>
          <ModalBody>
            {t("delete_confirmation", { entity: t("client") })}
            {"  "}
            {selected_client && selected_client.company_name.value}?{/* {selectedreport && selectedreport.company_name}? */}
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => doDelete()}>
              {t("yes")}
            </button>
            <button className="btn btn-secondary" onClick={() => opencloseModalDelete()}>
              {t("no")}
            </button>
          </ModalFooter>
        </Modal>
      )}
      {userPermissions[3] === "1" && (
        <Modal isOpen={modalEmail}>
          <ModalHeader toggle={() => opencloseModalEmail()}>
            <div style={{ padding: "5px" }}>
              {t("send_email_to")}
              {":   "} {selected_client && selected_client.email.value}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <input className="form-control lightgreyBack" placeholder={t("subject")} type="text" name="subject" onChange={handleEmailSubject} id="subject" />
            </div>
            <div className="form-group">
              <textarea rows="15" className="form-control lightgreyBack" name="body" onChange={handleEmailBody} id="body" />
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <button className="btn orangeBack" onClick={() => sendEmail()}>
                <InlineIcon icon="line-md:telegram" />
              </button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </Margin>
  );
}

export default ClientCRUD;
